import React, { useEffect } from 'react'
import { Form, Input, Button, DatePicker, message, ConfigProvider } from 'antd'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useNavigate,
  Link,
} from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import AuthorizationLayout from '../components/layout/AuthorizationLayout.jsx'

import { logoutAccount } from '../redux/actions/user'

function AgreementPaper(props) {
  const { user } = props

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  console.log(id)

  const [clientData, setClientData] = React.useState(null)

  const getClientData = async () => {
    var config = {
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER}/api/user/singleClient?_id=${id}`,
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    }

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.client))
        console.log(response.data.client)
        setClientData(response.data.client)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    getClientData()
  }, [])

  if (!clientData) return <div />

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.topRow}>
          <div>
            <img
              // src="https://mypublicsharings.s3.amazonaws.com/random/temp-sharing-delete-later/sheba+shangstha+logo.jpg"
              src="/sheba-shangstha-logo-png-format.png"
              alt=""
              style={styles.image}
            />
          </div>
          <div style={{ paddingLeft: 10, paddingRight: 5 }}>
            <h1 className="margin-zero" style={styles.h1}>
              CHATTAGRAM SHEBA SHANGSTHA
            </h1>
            <p className="margin-zero" style={styles.p}>
              Dudu plasio (Ground Floor).1059,O.R Nizam, Golpahar
            </p>
            <p className="margin-zero" style={styles.p}>
              Mobile : 01707007313, 01868935760
            </p>
          </div>
        </div>
        <hr style={styles.hr} />
        <hr style={styles.hr} />

        <div style={styles.middleRow}>
          <p style={{ fontSize: 17 }}>Agreement ID: {clientData.agreementId}</p>
          <p style={{ fontSize: 17 }}>Date: {moment().format('DD-MM-YYYY')}</p>
        </div>

        <div
          style={{
            paddingLeft: 35,
            paddingRight: 35,
            position: 'relative',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // border: '1px solid red',
              position: 'absolute',
              paddingTop: 20,
            }}
          >
            <img
              src="/sheba-shangstha-logo-png-format.png"
              // style={styles.overlayImageStyle}
              style={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                opacity: 0.1,
                height: '70%',
                width: '80%',
              }}
            />
          </div>
          <h2 style={styles.h2}>Mutual Agreement</h2>
          <h2>
            “<b>Chattagram Sheba Shangstha</b>” takes responsibility of sweeping
            away daily medical wastages or garbages from public and private
            hospital, diagnostic center,clinic etc.
          </h2>
          <h2>
            <b> Assignment NO :</b> {clientData.assignmentId}
          </h2>
          <div
            style={{
              display: 'flex',
              // justifyContent: 'space-between',
              marginLeft: 0,
              marginTop: 20,
              marginBottom: 20,
              // marginRight: 300,
              // border: '1px solid red',
            }}
          >
            <h2 style={{}}>
              <b>Valid from:</b>{' '}
              {moment(clientData.startDate).format('DD-MM-YYYY')}
            </h2>
            <h2 style={{ marginLeft: 30 }}>
              <b>To: </b> {moment(clientData.endDate).format('DD-MM-YYYY')}
            </h2>
          </div>
          <h2 style={{ marginTop: 20, marginBottom: 30 }}>
            <b>
              From Chattagarm Sheba Shangstha <br /> Representative :
            </b>{' '}
            {clientData.shebaRepresentative}
          </h2>
          <h2>
            <b>
              2nd Party <br />
              {clientData.clientType} :{' '}
            </b>
            {clientData.companyName}
          </h2>
          <h2>
            <b>Representative :</b> {clientData.customerName}
          </h2>
          <h2>
            <b>Address : </b>
            {clientData.customerAddress}
          </h2>
          <h2>
            <b>Cell :</b> {clientData.contactNumber}
          </h2>
          <h2 style={{ marginTop: 20 }}>
            We will give {clientData.billPerMonth} ({' '}
            {capitalize(inWords(clientData.billPerMonth))}) BDT cash/cheque to “
            <b>Chattagram Sheba Shangstha </b>” for sweeping and transporting
            wastages and garbages of our company as per the charts of service
            charges determined by-
          </h2>
          <h2>
            “<b>Chittagong City Corporation Wastage Management Committee</b>”.
          </h2>
          <h2 style={{ fontStyle: 'italic' }}>
            NB : bill will be increased by 20% each year
          </h2>
          {clientData.remarks && (
            <div>
              <h2>
                <b>Remarks:</b> {clientData.remarks}
              </h2>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: 0,
              marginTop: 50,
              marginBottom: 100,
            }}
          >
            <h2 style={{ fontWeight: 'bold' }}>Chattogram Sheba Shangstha </h2>
            <h2 style={{ fontWeight: 'bold' }}>2nd Party </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  innerContainer: {
    fontFamily: 'Times New Roman',
    maxWidth: '48rem',
  },
  overlayImageStyle: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0.1,
    height: '70%',
    width: '80%',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 100,
  },
  h1: {
    fontSize: '1.90em',
    fontWeight: 'bold',
  },
  p: {
    textAlign: 'center',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    fontSize: 13,
  },
  hr: {
    width: '90%',
  },
  middleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 25,
    paddingLeft: 35,
    paddingRight: 35,
  },
  h2: {
    textDecoration: 'underline',
    textAlign: 'center',
  },
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(AgreementPaper)

var a = [
  '',
  'one ',
  'two ',
  'three ',
  'four ',
  'five ',
  'six ',
  'seven ',
  'eight ',
  'nine ',
  'ten ',
  'eleven ',
  'twelve ',
  'thirteen ',
  'fourteen ',
  'fifteen ',
  'sixteen ',
  'seventeen ',
  'eighteen ',
  'nineteen ',
]
var b = [
  '',
  '',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
]

function inWords(num) {
  var n
  if ((num = num.toString()).length > 9) return 'overflow'
  n = ('000000000' + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/)
  if (!n) return
  var str = ''
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore '
      : ''
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh '
      : ''
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand '
      : ''
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred '
      : ''
  str +=
    n[5] != 0
      ? (str != '' ? 'and ' : '') +
        (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) +
        'only '
      : ''
  return str
}

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)
