import React from 'react'
import { Form, Input, Button, DatePicker, message, Radio } from 'antd'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useNavigate,
  Link,
} from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import axios from 'axios'
import AuthorizationLayout from '../components/layout/AuthorizationLayout.jsx'

import { logoutAccount } from '../redux/actions/user'

function AddAClient(props) {
  const { user } = props

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {}, [])

  const [form] = Form.useForm()

  const onFinish = (values) => {
    console.log('Success:', values)
    // When submitting add the name of the user(or admin) who added this entry
    axios
      .post(
        `${process.env.REACT_APP_SERVER}/api/user/createClient`,
        {
          ...values,
          addedById: user.user.data.user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${user.user.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response)
        navigate('/clients')
      })
      .catch((error) => {
        console.error(error)
        message.error('Failed to add client')
      })
  }

  return (
    <AuthorizationLayout>
      <div style={{ padding: 10 }}>
        <h1>Fill up the form to add new client</h1>
        <Form form={form} onFinish={onFinish}>
          {/* <Form.Item
            label="Client Type"
            name="clientType"
            rules={[
              { required: true, message: 'Please input the company name!' },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="e.g. Diagnostic Center, Company" />
          </Form.Item> */}

          <Form.Item
            name="clientType"
            label="Client Type"
            rules={[{ required: true, message: 'Please input the type!' }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group>
              <Radio value="Hospital">Hospital</Radio>
              <Radio value="Clinic">Clinic</Radio>
              <Radio value="Diagnostic Centre">Diagnostic Centre</Radio>
              <Radio value="Lab">Lab</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Name"
            name="companyName"
            rules={[{ required: true, message: 'Please input the name!' }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Contact Number"
            name="contactNumber"
            rules={[
              { required: true, message: 'Please input the contact number!' },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Company Representative Name"
            name="customerName"
            rules={[
              { required: true, message: 'Please input the customer name!' },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Customer Address"
            name="customerAddress"
            rules={[
              { required: true, message: 'Please input the customer address!' },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Bill per Month"
            name="billPerMonth"
            rules={[
              { required: true, message: 'Please input the bill per month!' },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input
              type="number"
              max={99999}
              placeholder="Value must be less then 1,00,000"
            />
          </Form.Item>
          <Form.Item
            label="Remarks (Optional)"
            name="remarks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Sheba shongstha representative"
            name="shebaRepresentative"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: 'Please input the representative name.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[
              { required: true, message: 'Please input the start date!' },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="endDate"
            rules={[{ required: true, message: 'Please input the end date!' }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthorizationLayout>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(AddAClient)
