import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons'
import ReactToPrint from 'react-to-print'
import {
  Space,
  Table,
  Button,
  Input,
  Form,
  DatePicker,
  message,
  Spin,
} from 'antd'

function PaidLogs(props) {
  const { user } = props

  const [allCosts, setAllCosts] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalEarnings, setTotalEarnings] = useState(0)
  const [totalSpending, setTotalSpending] = useState(0)
  const [renderStatments, setRenderStatement] = useState([])
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const componentRef = useRef()
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              console.log(clearFilters())
              handleSearch(selectedKeys, confirm, dataIndex)
              clearFilters && handleReset(clearFilters)
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    // render: (text) => (searchedColumn === dataIndex ? text : text),
    // render: (text) => ,
  })

  const sharedOnCell = (_, index) => {
    if (index == renderStatments.length - 1) {
      return {
        colSpan: 0,
      }
    }
    return {}
  }

  const renderColumn = [
    {
      title: 'No.',
      // dataIndex: 'costId',
      key: 'costId',
      render: (data, record, index) => (
        <span>
          <Space>
            <p>{record.costPurpose != 'Total collected' && `${index + 1}`}</p>
          </Space>
        </span>
      ),
      onCell: sharedOnCell,
    },
    {
      title: 'Company Name',
      // dataIndex: 'companyName',
      key: 'companyName',
      ...getColumnSearchProps('companyName'),
      render: (data, record) => (
        <span>
          {/* <p>{JSON.stringify(data)}</p> */}
          <Space>
            <p>{data.companyName}</p>
          </Space>
        </span>
      ),
      // Todo: if costType: employees then show employee name, if costType: cars then show car number, if office then just show "Office" string.
      onCell: (_, index) => {
        // console.log(`index: ${index}`)
        // console.log(index != renderStatments.length - 1)
        return {
          colSpan: index != renderStatments.length - 1 ? 1 : 4,
        }
      },
    },
    {
      title: 'Paid For',
      dataIndex: 'monthToBePaid',
      key: 'monthToBePaid',
      onCell: sharedOnCell,
    },
    {
      title: 'Paid Amount',
      dataIndex: 'paidAmount',
      key: 'paidAmount',
      onCell: sharedOnCell,
    },
    {
      title: 'Created At',
      // dataIndex: 'createdAt',
      // key: 'createdAt',
      render: (data) => {
        if (data.totalSpending) return data.totalSpending
        return moment(data.createdAt).format('DD-MM-YYYY')
      },
    },
    {
      title: 'Collected by',
      dataIndex: 'costBy',
      key: 'costBy',
      render: (data, record) => (
        <span>
          <Space>
            <p>
              {data?.length > 0 ? (
                `${data[0]?.employeeName} - ${data[0].designation}`
              ) : (
                <span style={{ color: '#A52A2A' }}>No Data</span>
              )}
            </p>
          </Space>
        </span>
      ),
    },
    // {
    //   title: 'Date',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render: (dt) => moment(dt).format('DD-MM-YYYY'),
    //   sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    //   onFilter: (value, record) =>
    //     moment(record.createdAt).isSame(moment(value), 'day'),
    // },
  ]

  // ! keeping just for reference
  const customColumn = {
    title: 'Custom Column',
    render: (text, record, index) => {
      // console.log('Custom Column down')
      // console.log(index)
      // console.log(renderStatments.length - 2)
      // console.log('Custom Column up')
      if (index === renderStatments.length - 1) {
        return <p style={{ border: '1px solid red' }}>Test if</p>
      } else {
        return null
      }
    },
  }

  const getPaidBillsViaDateRange = async (fromD, toD) => {
    // console.log(`getPaidBillsViaDateRange()`)
    setLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER}/api/user/getPaidBillsViaDateRange?from=${fromD}&to=${toD}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.user.token}`,
          },
        }
      )
      const data = await response.json()
      // console.log(data)

      setAllCosts(data.allPaidBills)
      setLoading(false)
      return data.allPaidBills
    } catch (err) {
      setLoading(false)
      console.log(err)
      message.error('Please try again')
    }
  }

  const onFinish = async (values) => {
    setRenderStatement([])
    // console.log('Success:', values)
    const { fromDate, toDate } = values
    const isGreater = fromDate.isAfter(toDate)
    // console.log(isGreater) // true
    if (isGreater) {
      message.error('Start date must be less than or equal end date')
      setFromDate(null)
      setToDate(null)
      return
    }
    const result = await getPaidBillsViaDateRange(fromDate, toDate) // to show the first row of the table and get total earning

    setFromDate(fromDate)
    setToDate(toDate)
    // Get total paid bills during the selected date range

    let tempTotalSpendings = 0
    let costItemFoundToRender = 0 // if 0 item added after filter add the row outside the "isWithinRange" if block
    // console.log(allCosts.length)
    // console.log(allCosts)
    // Get total costing during the selected date range
    //* result is same as "allCosts" but not available because of delayed state update via setAllCosts
    result?.forEach((cost, idx) => {
      // console.log(cost.createdAt)
      const costDate = moment(cost.createdAt).format('YYYY-MM-DD')
      // console.log(moment(cost.createdAt).format('YYYY-MM-DD'))
      const startDate = moment(values.fromDate).subtract(1, 'day')
      // const endDate = moment(values.toDate).add(1, 'day')
      // const startDate = moment(values.fromDate)
      const endDate = moment(values.toDate)
      const dateToCheck = moment(costDate)
      const isWithinRange = dateToCheck.isBetween(startDate, endDate)
      if (idx === 0) {
        // console.log(startDate)
        // console.log(endDate)
        // console.log(costDate, isWithinRange)
      } else {
        // console.log(costDate, isWithinRange)
      }
      if (isWithinRange) {
        tempTotalSpendings += cost.paidAmount
        costItemFoundToRender += 1
        setTotalSpending((prev) => prev + cost.paidAmount) // Just storing incase required in other place of page
        setRenderStatement((prev) => [...prev, cost])
        // console.log(idx)
        // console.log(result.length)
        // console.log(`result ${idx}: ${result[idx].costPurpose}`)
        // console.log(result[idx])
        // console.log(tempTotalSpendings)
        // console.log(result[10])
        if (idx === result.length - 1) {
          // console.log('Total collected: ', tempTotalSpendings)
          setRenderStatement((prev) => [
            ...prev,
            {
              companyName: 'Total collected',
              totalSpending: `${tempTotalSpendings} (${capitalize(
                inWords(tempTotalSpendings)
              )})`,
            },
          ])
        }
      }
      if (costItemFoundToRender === 0 && idx === result.length - 1) {
        setRenderStatement((prev) => [
          ...prev,
          { companyName: 'Total collected', totalSpending: 0 },
        ])
      }
    })
    // console.log(onFinishCount)

    // if (onFinishCount == 0) {
    //   // onFinish(values)
    //   console.log(onFinishCount)
    //   setOnFinishCount(++onFinishCount)
    // }
  }

  useEffect(() => {
    //
  }, [])

  return (
    <div style={{ marginTop: 20 }}>
      <ReactToPrint
        trigger={() => <button>Print</button>}
        content={() => componentRef.current}
      />
      {/* <p>Total data: {allCosts.length}</p> */}
      <div>
        <Form onFinish={onFinish}>
          <Form.Item
            label="From Date"
            name="fromDate"
            rules={[
              { required: true, message: 'Please input the start date!' },
            ]}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 20 }}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="To Date"
            name="toDate"
            rules={[{ required: true, message: 'Please input the end date!' }]}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 20 }}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              Submit {loading && <LoadingOutlined style={{ color: '#08c' }} />}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Table
        pagination={false}
        bordered
        ref={componentRef}
        dataSource={renderStatments}
        columns={[...renderColumn]}
        // rowKey="_id"
        scroll={{ x: true }}
        style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 20 }}
        title={() => (
          <>
            <p style={{ textAlign: 'center', margin: 0 }}>
              <b>CHATTAGRAM SHEBA SHANGSTHA</b>
            </p>
            {fromDate && toDate && (
              <p style={{ textAlign: 'center', margin: 0 }}>
                Statement for
                <b>{moment(fromDate).format('DD MMMM YYYY')}</b> to{' '}
                <b>{moment(toDate).format('DD MMMM YYYY')}</b>
                {/* {` ${
                  fromDate ? moment(fromDate).format('DD MMMM YYYY') : ''
                } to ${toDate ? moment(toDate).format('DD MMMM YYYY') : ''}`} */}
              </p>
            )}
            {/* <p style={{ margin: 0 }}>Costings. Total data: {allCosts.length}</p> */}
          </>
        )}
      />
    </div>
  )
}

const styles = {
  container: {
    padding: 10,
    margin: 10,
    fontFamily: 'Times New Roman',
  },
}

function inWords(num) {
  var a = [
    '',
    'one ',
    'two ',
    'three ',
    'four ',
    'five ',
    'six ',
    'seven ',
    'eight ',
    'nine ',
    'ten ',
    'eleven ',
    'twelve ',
    'thirteen ',
    'fourteen ',
    'fifteen ',
    'sixteen ',
    'seventeen ',
    'eighteen ',
    'nineteen ',
  ]
  var b = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ]
  var n
  if ((num = num.toString()).length > 9) return 'overflow'
  n = ('000000000' + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/)
  if (!n) return
  var str = ''
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore '
      : ''
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh '
      : ''
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand '
      : ''
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred '
      : ''
  str +=
    n[5] != 0
      ? (str != '' ? 'and ' : '') +
        (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) +
        'only '
      : ''
  return str
}

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(PaidLogs)
