import React from 'react'
import { Form, Input, Button, DatePicker, message, Radio } from 'antd'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useNavigate,
  Link,
} from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import AuthorizationLayout from '../components/layout/AuthorizationLayout.jsx'

import { logoutAccount } from '../redux/actions/user'

function EditAClient(props) {
  const { user } = props

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const [loading, setLoading] = React.useState(false)
  const [clientData, setClientData] = React.useState(null)

  React.useEffect(() => {
    getClientData()
  }, [])

  const getClientData = async () => {
    var config = {
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER}/api/user/singleClient?_id=${id}`,
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    }

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data.client))
        console.log(response.data.client)
        setClientData(response.data.client)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const [form] = Form.useForm()

  const onFinish = (values) => {
    console.log(values)
    console.log({ ...clientData, ...values })

    // return
    // When submitting add the name of the user(or admin) who added this entry (in here not editing here)
    axios
      .post(
        `${process.env.REACT_APP_SERVER}/api/user/editClient`,
        { ...clientData, ...values },
        {
          headers: {
            Authorization: `Bearer ${user.user.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response)
        navigate('/clients')
      })
      .catch((error) => {
        console.error(error)
        message.error('Failed to add client')
      })
  }

  if (!clientData) return <div></div>

  return (
    <AuthorizationLayout>
      <div style={{ padding: 10 }}>
        <h1>Edit client</h1>
        {/* <p>{JSON.stringify(clientData.companyName)}</p> */}
        <Form
          // form={form}
          onFinish={onFinish}
          initialValues={{
            ...clientData,
            startDate: moment(clientData.startDate),
            endDate: moment(clientData.endDate),
          }}
        >
          {/* <Form.Item
            label="Client Type"
            name="clientType"
            rules={[
              { required: true, message: 'Please input the company name!' },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input placeholder="e.g. Diagnostic Center, Company" />
          </Form.Item> */}
          <Form.Item
            name="clientType"
            label="Client Type"
            rules={[{ required: true, message: 'Please input the type!' }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group>
              <Radio value="Hospital">Hospital</Radio>
              <Radio value="Clinic">Clinic</Radio>
              <Radio value="Diagnostic Centre">Diagnostic Centre</Radio>
              <Radio value="Lab">Lab</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Name"
            name="companyName"
            rules={[
              { required: true, message: 'Please input the company name!' },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Contact Number"
            name="contactNumber"
            rules={[
              { required: true, message: 'Please input the contact number!' },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Company Representative Name"
            name="customerName"
            rules={[
              { required: true, message: 'Please input the customer name!' },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Customer Address"
            name="customerAddress"
            rules={[
              { required: true, message: 'Please input the customer address!' },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Bill per Month"
            name="billPerMonth"
            rules={[
              { required: true, message: 'Please input the bill per month!' },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Remarks (Optional)"
            name="remarks"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Sheba shongstha representative"
            name="shebaRepresentative"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[
              { required: true, message: 'Please input the start date!' },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="endDate"
            rules={[{ required: true, message: 'Please input the end date!' }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthorizationLayout>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(EditAClient)
