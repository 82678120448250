import React from 'react'
import './App.css'
import axios from 'axios'
import { Provider } from 'react-redux'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  Navigate,
} from 'react-router-dom'
// import Layout from './components/layout/Layout'

import store from './redux/store'

import LandingPage from './pages/LandingPage'
import Auth from './auth/Auth'
import RedirectOnly from './pages/RedirectOnly'
import Login from './pages/Login'
import MyProfile from './pages/MyProfile'
import Clients from './pages/Clients'
import AddAClient from './pages/AddAClient'
import EditAClient from './pages/EditAClient'
import AgreementPaper from './pages/AgreementPaper'
import Billings from './pages/Billings'
import ViewBillingPaper from './pages/ViewBillingPaper'
import ViewBillingPaperBulk from './pages/ViewBillingPaperBulk'
import Costings from './pages/Costings'
import CompanyData from './pages/CompanyData'

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route>
              <Route path="/" element={<RedirectOnly />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/dashboard"
                element={<Auth children={<LandingPage />} />}
              />
              <Route
                path="/clients"
                element={<Auth children={<Clients />} />}
              ></Route>
              <Route path="/clients/addNew" element={<AddAClient />}></Route>
              <Route path="/clients/edit/:id" element={<EditAClient />}></Route>
              <Route path="/agreement/:id" element={<AgreementPaper />}></Route>

              <Route
                path="/billing"
                element={<Auth children={<Billings />} />}
              ></Route>
              <Route
                path="/bill/:clientId/:billGenerationMonth/:billGenerationYear"
                element={<ViewBillingPaper />}
              ></Route>
              <Route
                path="/bulkBillPrint/:billGenerationMonth/:billGenerationYear"
                element={<ViewBillingPaperBulk />}
              ></Route>

              <Route
                path="/costing"
                element={<Auth children={<Costings />} />}
              ></Route>

              <Route
                path="/company-data"
                element={<Auth children={<CompanyData />} />}
              ></Route>

              <Route
                path="/my-profile"
                element={<Auth children={<MyProfile />} />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  )
}

export default App
