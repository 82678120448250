import React, { useEffect } from 'react'
import { Form, Input, Button, DatePicker, message, ConfigProvider } from 'antd'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useNavigate,
  Link,
  useLocation,
} from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import AuthorizationLayout from '../components/layout/AuthorizationLayout.jsx'

import { logoutAccount } from '../redux/actions/user'

function ViewBillingPaperBulk(props) {
  const { user } = props

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { clientId, billGenerationMonth, billGenerationYear } = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const billR = searchParams.get('billR')
  const customDate = searchParams.get('customDate')
  console.log(billR)

  const [generatedBills, setGeneratedBills] = React.useState([])
  // const [loading, setLoading] = React.useState(false)
  const [billRemarks, setBillRemarks] = React.useState(``)

  const generateBill = async (clientId) => {
    var config = {
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER}/api/user/generateBill`,
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
      data: {
        clientId,
        billGenerationMonth,
        billGenerationYear,
      },
    }

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data))
        console.log(response.data)
        setGeneratedBills((prev) => [...prev, response.data])
        if (response.data === null) generateBill()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    // generateBill()
    // console.log(clientId)
    // console.log(clientId.split(','))
    if (generatedBills.length === 0) {
      let itemsToPrint = localStorage.getItem('itemsToPrint')
      console.log(itemsToPrint)
      // itemsToPrint = JSON.parse(itemsToPrint)

      itemsToPrint.split(',').forEach((el, idx) => {
        generateBill(el)
      })
    }
  }, [])

  if (generatedBills.length === 0) return <div />

  return (
    <>
      {generatedBills.map((generatedBill, idx) => {
        return (
          <div key={idx}>
            <div style={{ ...styles.container, marginBottom: 30 }}>
              <div style={styles.innerContainer}>
                <div style={styles.topRow}>
                  <div>
                    <img
                      // src="https://mypublicsharings.s3.amazonaws.com/random/temp-sharing-delete-later/sheba+shangstha+logo.jpg"
                      src="/sheba-shangstha-logo-png-format.png"
                      alt=""
                      style={styles.image}
                    />
                  </div>
                  <div style={{ paddingLeft: 10, paddingRight: 5 }}>
                    <h1 className="margin-zero" style={styles.h1}>
                      CHATTAGRAM SHEBA SHANGSTHA
                    </h1>
                    <p
                      className="billingPaperView-p-tag-common"
                      style={styles.p}
                    >
                      Dudu plasio (Ground Floor).1059,O.R Nizam, Golpahar
                    </p>
                    <p
                      className="billingPaperView-p-tag-common"
                      style={styles.p}
                    >
                      Mobile : 01707007313, 01868935760
                    </p>
                  </div>
                </div>
                <div style={styles.maxWidthAndItemsCenter}>
                  <p style={styles.roundedBorderWithPadding}>
                    Office Bill Copy{' '}
                  </p>
                </div>
                <div style={styles.containerWithSpaceBetween}>
                  <p
                    style={styles.marginZero}
                    className="billingPaperView-p-tag-common"
                  >
                    Bll NO: {generatedBill.billNo}{' '}
                  </p>
                  <p
                    style={styles.marginZero}
                    className="billingPaperView-p-tag-common"
                  >
                    Date: {customDate ? customDate : generatedBill.date}
                  </p>
                </div>
                <p
                  style={{
                    ...styles.marginZero,
                    lineHeight: 1,
                    fontWeight: 'bold',
                  }}
                  // className="billingPaperView-p-tag-common"
                  className="billingPaperView-p-tag-client-org-name"
                >
                  <span style={{ fontWeight: 'bold' }}>
                    Name of the {generatedBill.clientType}:{' '}
                  </span>
                  {generatedBill.companyName}
                </p>
                <p
                  style={{ ...styles.marginZero, lineHeight: 1 }}
                  className="billingPaperView-p-tag-common"
                >
                  <span style={{ fontWeight: 'bold' }}>Customer ID: </span>
                  {generatedBill.fileNumber}
                </p>
                <p
                  style={{ ...styles.marginZero, lineHeight: 1 }}
                  className="billingPaperView-p-tag-common"
                >
                  <span style={{ fontWeight: 'bold' }}>Address: </span>
                  {generatedBill.customerAddress}
                </p>
                {/* <p
            style={{ ...styles.marginZero, lineHeight: 1 }}
            className="billingPaperView-p-tag-common"
          >
            <span style={{ fontWeight: 'bold' }}>Billing Month: </span>
            {generatedBill.billingMonth}
          </p> */}
                <p
                  style={{ ...styles.marginZero, lineHeight: 1 }}
                  className="billingPaperView-p-tag-common"
                >
                  <span style={{ fontWeight: 'bold' }}>Billing purpose: </span>
                  The work of medical wastes collection and Carrying
                </p>

                <p
                  style={{ ...styles.marginZero, paddingTop: 2 }}
                  className="billingPaperView-p-tag-common"
                >
                  Dear sir, <br />
                  The order of medical wastes collection & carrying from your{' '}
                  {generatedBill.clientType} has already been worked. <br />
                  The total amount is given below.
                </p>
                <div
                  style={{
                    // border: '1px solid black',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      border: '1px solid black',
                      width: '70.1%',
                      textAlign: 'center',
                      fontSize: 11,
                    }}
                  >
                    Description
                  </div>
                  <div
                    style={{
                      border: '1px solid black',
                      borderLeft: 'none',
                      width: '29.9%',
                      textAlign: 'center',
                      fontSize: 11,
                    }}
                  >
                    Amount
                  </div>
                </div>
                <div
                  style={{
                    border: '1px solid black',
                    borderTop: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ height: 30, display: 'flex' }}>
                    <div
                      style={{
                        width: '70%',
                        textAlign: 'center',
                        fontSize: 18,
                      }}
                    >
                      {/* Total bill for this month */}
                      Bill of month{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {generatedBill.billingMonth} {billGenerationYear}
                      </span>
                    </div>
                    <div
                      style={{
                        width: '30%',
                        borderLeft: '1px solid black',
                        textAlign: 'center',
                        fontSize: 13,
                        fontWeight: 'bold',
                      }}
                    >
                      {generatedBill.currentMonthAmount} Taka
                    </div>
                  </div>
                  <div style={{ height: 20, display: 'flex' }}>
                    <div
                      style={{
                        width: '70%',
                        textAlign: 'end',
                        paddingRight: 5,
                        fontSize: 11,
                      }}
                    >
                      Previous Due
                    </div>
                    <div
                      style={{
                        width: '30%',
                        borderLeft: '1px solid black',
                        textAlign: 'center',
                        borderTop: '1px solid black',
                        fontSize: 13,
                        fontWeight: 'bold',
                      }}
                    >
                      {generatedBill.prevousDue} Taka
                    </div>
                  </div>
                  <div style={{ height: 20, display: 'flex' }}>
                    <div
                      style={{
                        width: '70%',
                        textAlign: 'end',
                        paddingRight: 5,
                        fontSize: 11,
                      }}
                    >
                      Total Payable Amount
                    </div>
                    <div
                      style={{
                        width: '30%',
                        borderLeft: '1px solid black',
                        textAlign: 'center',
                        borderTop: '1px solid black',
                        fontSize: 13,
                        fontWeight: 'bold',
                      }}
                    >
                      {generatedBill.totalDue} Taka
                    </div>
                  </div>
                </div>
                <p
                  style={styles.marginZero}
                  className="billingPaperView-p-tag-common"
                >
                  In Words: {generatedBill.totalDueInWords} Bangladeshi Taka
                  only
                </p>
                {billRemarks && (
                  <p
                    style={{ ...styles.marginZero, paddingTop: 2 }}
                    className="billingPaperView-p-tag-common"
                  >
                    Remarks: {billRemarks}
                  </p>
                )}

                <p
                  style={{ fontWeight: 'bolder', marginTop: 15 + 30 }}
                  className="billingPaperView-p-tag-common"
                >
                  Authorized by
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // border: '1px solid red',
                  position: 'absolute',
                  paddingTop: 90,
                }}
              >
                <img
                  src="/sheba-shangstha-logo-png-format.png"
                  // style={styles.overlayImageStyle}
                  style={{
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    opacity: 0.1,
                    height: '30%',
                    width: '40%',
                  }}
                />
              </div>
            </div>
            <hr style={{ border: 'dashed 1px' }} />
            <div style={styles.container}>
              <div style={styles.innerContainer}>
                <div style={styles.topRow}>
                  <div>
                    <img
                      // src="https://mypublicsharings.s3.amazonaws.com/random/temp-sharing-delete-later/sheba+shangstha+logo.jpg"
                      src="/sheba-shangstha-logo-png-format.png"
                      alt=""
                      style={styles.image}
                    />
                  </div>
                  <div style={{ paddingLeft: 10, paddingRight: 5 }}>
                    <h1 className="margin-zero" style={styles.h1}>
                      CHATTAGRAM SHEBA SHANGSTHA
                    </h1>
                    <p
                      className="billingPaperView-p-tag-common"
                      style={styles.p}
                    >
                      Dudu plasio (Ground Floor).1059,O.R Nizam, Golpahar
                    </p>
                    <p
                      className="billingPaperView-p-tag-common"
                      style={styles.p}
                    >
                      Mobile : 01707007313, 01868935760
                    </p>
                  </div>
                </div>
                <div style={styles.maxWidthAndItemsCenter}>
                  <p style={styles.roundedBorderWithPadding}>
                    Customer Bill Copy
                  </p>
                </div>
                <div style={styles.containerWithSpaceBetween}>
                  <p
                    style={styles.marginZero}
                    className="billingPaperView-p-tag-common"
                  >
                    Bll NO: {generatedBill.billNo}{' '}
                  </p>
                  <p
                    style={styles.marginZero}
                    className="billingPaperView-p-tag-common"
                  >
                    Date: {customDate ? customDate : generatedBill.date}
                  </p>
                </div>
                <p
                  style={{
                    ...styles.marginZero,
                    lineHeight: 1,
                    fontWeight: 'bold',
                  }}
                  // className="billingPaperView-p-tag-common"
                  className="billingPaperView-p-tag-client-org-name"
                >
                  <span style={{ fontWeight: 'bold' }}>
                    Name of the {generatedBill.clientType}:{' '}
                  </span>
                  {generatedBill.companyName}
                </p>
                <p
                  style={{ ...styles.marginZero, lineHeight: 1 }}
                  className="billingPaperView-p-tag-common"
                >
                  <span style={{ fontWeight: 'bold' }}>Customer ID: </span>
                  {generatedBill.fileNumber}
                </p>
                <p
                  style={{ ...styles.marginZero, lineHeight: 1 }}
                  className="billingPaperView-p-tag-common"
                >
                  <span style={{ fontWeight: 'bold' }}>Address: </span>
                  {generatedBill.customerAddress}
                </p>
                {/* <p
            style={{ ...styles.marginZero, lineHeight: 1 }}
            className="billingPaperView-p-tag-common"
          >
            <span style={{ fontWeight: 'bold' }}>Billing Month: </span>
            {generatedBill.billingMonth}
          </p> */}
                <p
                  style={{ ...styles.marginZero, lineHeight: 1 }}
                  className="billingPaperView-p-tag-common"
                >
                  <span style={{ fontWeight: 'bold' }}>Billing purpose: </span>
                  The work of medical wastes collection and Carrying
                </p>

                <p
                  style={{ ...styles.marginZero, paddingTop: 2 }}
                  className="billingPaperView-p-tag-common"
                >
                  Dear sir, <br />
                  The order of medical wastes collection & carrying from your{' '}
                  {generatedBill.clientType} has already been worked. <br />
                  The total amount is given below.
                </p>
                <div
                  style={{
                    // border: '1px solid black',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      border: '1px solid black',
                      borderRight: `1px solid black`,
                      width: '70.1%',
                      textAlign: 'center',
                      fontSize: 11,
                    }}
                  >
                    Description
                  </div>
                  <div
                    style={{
                      border: '1px solid black',
                      borderLeft: 'none',
                      width: '29.9%',
                      textAlign: 'center',
                      fontSize: 11,
                    }}
                  >
                    Amount
                  </div>
                </div>
                <div
                  style={{
                    border: '1px solid black',
                    borderTop: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }}
                >
                  <div style={{ height: 30, display: 'flex' }}>
                    <div
                      style={{
                        width: '70%',
                        textAlign: 'center',
                        fontSize: 18,
                      }}
                    >
                      {/* Total bill for this month  */}
                      Bill of month{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {generatedBill.billingMonth} {billGenerationYear}
                      </span>
                    </div>
                    <div
                      style={{
                        width: '30%',
                        borderLeft: '1px solid black',
                        textAlign: 'center',
                        fontSize: 13,
                        fontWeight: 'bold',
                      }}
                    >
                      {generatedBill.currentMonthAmount} Taka
                    </div>
                  </div>
                  <div style={{ height: 20, display: 'flex' }}>
                    <div
                      style={{
                        width: '70%',
                        textAlign: 'end',
                        paddingRight: 5,
                        fontSize: 11,
                      }}
                    >
                      Previous Due
                    </div>
                    <div
                      style={{
                        width: '30%',
                        borderLeft: '1px solid black',
                        textAlign: 'center',
                        borderTop: '1px solid black',
                        fontSize: 13,
                        fontWeight: 'bold',
                      }}
                    >
                      {generatedBill.prevousDue} Taka
                    </div>
                  </div>
                  <div style={{ height: 20, display: 'flex' }}>
                    <div
                      style={{
                        width: '70%',
                        textAlign: 'end',
                        paddingRight: 5,
                        fontSize: 11,
                      }}
                    >
                      Total Payable Amount
                    </div>
                    <div
                      style={{
                        width: '30%',
                        borderLeft: '1px solid black',
                        textAlign: 'center',
                        borderTop: '1px solid black',
                        fontSize: 13,
                        fontWeight: 'bold',
                      }}
                    >
                      {generatedBill.totalDue} Taka
                    </div>
                  </div>
                </div>
                <p
                  style={styles.marginZero}
                  className="billingPaperView-p-tag-common"
                >
                  In Words: {generatedBill.totalDueInWords} Bangladeshi Taka
                  only
                </p>
                {billRemarks && (
                  <p
                    style={{ ...styles.marginZero, paddingTop: 2 }}
                    className="billingPaperView-p-tag-common"
                  >
                    Remarks: {billRemarks}
                  </p>
                )}
                <p
                  style={{ fontWeight: 'bolder', marginTop: 14 + 30 }}
                  className="billingPaperView-p-tag-common"
                >
                  Authorized by
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // border: '1px solid red',
                  position: 'absolute',
                  paddingTop: 90,
                }}
              >
                <img
                  src="/sheba-shangstha-logo-png-format.png"
                  // style={styles.overlayImageStyle}
                  style={{
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    opacity: 0.1,
                    height: '30%',
                    width: '40%',
                  }}
                />
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  innerContainer: {
    fontFamily: 'Times New Roman',
    maxWidth: '48rem',
  },
  overlayImageStyle: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0.1,
    height: '70%',
    width: '80%',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 10,
  },
  image: {
    width: 70,
    height: 70,
    borderRadius: 100,
  },
  h1: {
    fontSize: '1.50em',
    fontWeight: 'bold',
  },
  p: {
    textAlign: 'center',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  hr: {
    width: '90%',
  },
  middleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 25,
    paddingLeft: 35,
    paddingRight: 35,
  },
  h2: {
    textDecoration: 'underline',
    textAlign: 'center',
  },
  maxWidthAndItemsCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  roundedBorderWithPadding: {
    padding: 2,
    borderRadius: 5,
    border: '1px solid black',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12,
    // maxWidth: 30,
    margin: 0,
  },
  containerWithSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    // marginTop: 5,
    paddingLeft: 35,
    paddingRight: 35,
  },
  marginZero: {
    margin: 0,
  },
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(ViewBillingPaperBulk)

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
