import React from 'react'
import { Typography, Menu, Spin, message, Button, BackTop, Result } from 'antd'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import axios from 'axios'
import {
  MenuUnfoldOutlined,
  ContainerOutlined,
  PieChartOutlined,
  WechatOutlined,
  QqOutlined,
  MessageOutlined,
  UserOutlined,
  FileSearchOutlined,
  BellOutlined,
  ProfileOutlined,
  TeamOutlined,
  BankOutlined,
  LogoutOutlined,
  DollarOutlined,
} from '@ant-design/icons'

import AuthorizationLayout from '../components/layout/AuthorizationLayout.jsx'
import { logoutAccount } from '../redux/actions/user'
import './styles/LandingPage.css'
import ViewCostingsTable from '../components/Dashboard/ViewCostingsTable'
import ViewBillsTable from '../components/Dashboard/ViewBillsTable'
import ViewStatements from '../components/Dashboard/ViewStatements'
import PaidLogs from '../components/Dashboard/PaidLogs'

function LandingPage(props) {
  const { user } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const categoryParamValue = searchParams.get('category')

  const [loading, setLoading] = React.useState(false)
  const [usersData, setUsersData] = React.useState([])
  const [serverDown, setServerDown] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState(null)

  React.useEffect(() => {
    if (categoryParamValue) {
      setSelectedItem(categoryParamValue)
    }
  }, [categoryParamValue, searchParams])

  return (
    <AuthorizationLayout>
      <div
        style={{
          // border: '3px solid red',
          // display: 'flex',
          // justifyContent: 'center',
          // alignItems: 'center',
          padding: 10,
        }}
      >
        <p style={{ fontSize: '1.5rem', marginBottom: 10 }}>Choose category</p>
        <div style={{}} className="LandingPage-choose-category-container">
          <div
            onClick={() => {
              setSelectedItem('costings')
              navigate(`/dashboard?category=costings`)
            }}
            style={
              selectedItem === 'costings' ? { ...styles.selectedColors } : {}
            }
            className="LandingPage-choose-category-item"
          >
            <p style={{ textAlign: 'center' }}>
              <DollarOutlined style={{ fontSize: 42 }} />
            </p>
            <p style={{ textAlign: 'center', marginBottom: 0 }}>
              View Costings
            </p>
          </div>
          <div
            style={selectedItem === 'bills' ? { ...styles.selectedColors } : {}}
            className="LandingPage-choose-category-item"
            onClick={() => {
              setSelectedItem('bills')
              navigate(`/dashboard?category=bills`)
            }}
          >
            <p style={{ textAlign: 'center' }}>
              <ProfileOutlined style={{ fontSize: 42 }} />
            </p>
            <p style={{ textAlign: 'center', marginBottom: 0 }}>View Bills</p>
          </div>
          <div
            style={
              selectedItem === 'statements' ? { ...styles.selectedColors } : {}
            }
            className="LandingPage-choose-category-item"
            onClick={() => {
              setSelectedItem('statements')
              navigate(`/dashboard?category=statements`)
            }}
          >
            <p style={{ textAlign: 'center' }}>
              <ContainerOutlined style={{ fontSize: 42 }} />
            </p>
            <p style={{ textAlign: 'center', marginBottom: 0 }}>
              View Statements
            </p>
          </div>
          <div
            style={
              selectedItem === 'statements-2'
                ? { ...styles.selectedColors }
                : {}
            }
            className="LandingPage-choose-category-item"
            onClick={() => {
              setSelectedItem('statements-2')
              navigate(`/dashboard?category=statements-2`)
            }}
          >
            <p style={{ textAlign: 'center' }}>
              <ContainerOutlined style={{ fontSize: 42 }} />
            </p>
            <p style={{ textAlign: 'center', marginBottom: 0 }}>Paid Logs</p>
          </div>
        </div>

        {selectedItem === 'costings' && <ViewCostingsTable />}
        {selectedItem === 'bills' && <ViewBillsTable />}
        {selectedItem === 'statements' && <ViewStatements />}
        {selectedItem === 'statements-2' && <PaidLogs />}
      </div>
    </AuthorizationLayout>
  )
}

const styles = {
  selectedColors: {
    backgroundColor: '#3C4048',
    color: '#fff',
  },
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(LandingPage)
