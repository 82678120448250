import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Dropdown, Menu, Button, Space, Table } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import AuthorizationLayout from '../components/layout/AuthorizationLayout'
import AddEmployee from '../components/Modal/AddEmployee'
import AddCarModal from '../components/Modal/AddCarModal'
import AddCostModal from '../components/Modal/AddCostModal'

function Costings(props) {
  const { user } = props
  const [addEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false)
  const [addCarModalOpen, setAddCarModalOpen] = useState(false)
  const [addCostModalOpen, setAddCostModalOpen] = useState(false)
  const [allCosts, setAllCosts] = useState([])

  const renderColumn = [
    // {
    //   title: 'ID',
    //   dataIndex: 'costId',
    //   key: 'costId',
    // },
    {
      title: 'Cost Type',
      dataIndex: 'costType',
      key: 'costType',
      render: (data, record) => (
        <span>
          <Space>
            <p>{data.toUpperCase()}</p>
          </Space>
        </span>
      ),
    },
    {
      title: 'Cost by',
      dataIndex: 'costBy',
      key: 'costBy',
      render: (data, record) => (
        <span>
          <Space>
            <p>
              {data.length > 0 ? (
                `${data[0]?.employeeName} - ${data[0].designation}`
              ) : (
                <span style={{ color: '#A52A2A' }}>No Data</span>
              )}
            </p>
          </Space>
        </span>
      ),
    },
    {
      title: 'For',
      // dataIndex: 'costType',
      key: 'costType',
      render: (data, record) => (
        <span>
          <Space>
            {data.carId && `${data.car[0].carNumber} - ${data.car[0].carName}`}
            {data.employeeId &&
              `${data.employee[0].employeeName} - ${data.employee[0].designation}`}
            {data.costType === 'offices' && 'Office'}
          </Space>
        </span>
      ),
    },
    {
      title: 'Taka',
      dataIndex: 'cost',
      key: 'cost',
    },
    {
      title: 'Purpose',
      // dataIndex: 'costPurpose',
      key: 'costPurpose',
      render: (data, record) => (
        <span>
          {/* <p>{JSON.stringify(data)}</p> */}
          <Space>
            <p>{data.costPurpose}</p>
          </Space>
        </span>
      ),
      // Todo: if costType: employees then show employee name, if costType: cars then show car number, if office then just show "Office" string.
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (dt) => moment(dt).format('DD-MM-YYYY'),
    },
  ]

  const getAllCosts = async () => {
    console.log(`getAllCosts()`)
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}/api/user/getAllCosts`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Send the token in the Authorization header
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    )
    const data = await response.json()
    console.log(data)
    setAllCosts(data.allCosts)
  }

  useEffect(() => {
    getAllCosts()
  }, [])

  return (
    <AuthorizationLayout>
      <div style={styles.container}>
        <AddEmployee
          visible={addEmployeeModalOpen}
          setVisible={setAddEmployeeModalOpen}
        />
        <AddCarModal
          visible={addCarModalOpen}
          setVisible={setAddCarModalOpen}
        />
        <AddCostModal
          visible={addCostModalOpen}
          setVisible={setAddCostModalOpen}
          getAllCosts={getAllCosts}
        />
        <div>Costings</div>
        <Space>
          <Button
            style={{ marginTop: 10 }}
            type="primary"
            onClick={() => setAddCostModalOpen(true)}
          >
            Add Cost
          </Button>
          <Dropdown
            style={{ marginTop: 10 }}
            type="outline"
            overlay={
              <Menu
                style={{ marginTop: 10, width: 150 }}
                onClick={(click) => {
                  console.log(click)
                  // if (click.key === '1') {
                  //   navigate('/post-job')
                  // }
                  // if (click.key === '2') {
                  //   navigate('/post-gig')
                  // }

                  if (click.key === '1') {
                    setAddEmployeeModalOpen(true)
                  }
                  if (click.key === '2') {
                    setAddCarModalOpen(true)
                  }
                }}
                items={[
                  {
                    key: '1',
                    label: 'An employee',
                  },
                  {
                    key: '2',
                    label: 'A vehicle',
                  },
                  //   {
                  //     key: '3',
                  //     label: 'An office',
                  //   },
                ]}
              />
            }
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Button style={{ marginTop: 10 }}>
                  Add Data
                  <DownOutlined />
                </Button>
              </Space>
            </a>
          </Dropdown>
        </Space>
        <Table
          dataSource={allCosts}
          columns={renderColumn}
          // rowKey="_id"
          scroll={{ x: true }}
        />
      </div>
    </AuthorizationLayout>
  )
}

const styles = {
  container: {
    padding: 10,
    margin: 10,
    fontFamily: 'Times New Roman',
  },
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(Costings)
