import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Dropdown, Select, Menu, Button, Space, Modal, Table } from 'antd'
import moment from 'moment'
import { DownOutlined, DeleteOutlined } from '@ant-design/icons'
import AuthorizationLayout from '../components/layout/AuthorizationLayout'
import AddEmployee from '../components/Modal/AddEmployee'

function CompanyData(props) {
  const { user } = props

  const [addEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false)
  const [displayData, setDisplayData] = useState(`employees`)
  const [renderData, setRenderData] = useState([])
  const [renderColumn, setRenderColumn] = useState([])
  const [allEmployees, setAllEmployees] = useState([])
  const [allCars, setAllCars] = useState([])
  const [visible, setVisible] = useState(null)

  const getAllEmployees = async () => {
    console.log(`getAllEmployees()`)
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}/api/user/getAllEmployees`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Send the token in the Authorization header
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    )
    const data = await response.json()
    console.log(data)
    setAllEmployees(data.employees)
  }

  // Delete an employee to this link "{{URL}}/api/user/deleteEmployee?_id={{employeeId}}"
  const deleteEmployee = async (employeeId) => {
    console.log(`deleteEmployee()`)
    console.log(employeeId)
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}/api/user/deleteEmployee?_id=${employeeId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    )
    const data = await response.json()
    console.log(data)
    getAllEmployees()
    setVisible(null)
  }

  const getAllCars = async () => {
    console.log(`getAllCars()`)
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}/api/user/getAllCars`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Send the token in the Authorization header
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    )
    const data = await response.json()
    console.log(data)
    setAllCars(data.carDatas)
  }

  const deleteCar = async (carId) => {
    console.log(`deleteCar()`)
    console.log(carId)
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}/api/user/deleteCar?_id=${carId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    )
    const data = await response.json()
    console.log(data)
    getAllCars()
    setVisible(null)
  }

  // Use effect to get all employees
  useEffect(() => {
    getAllEmployees()
    getAllCars()
  }, [])

  // Use effect to detect changes in displayData and set renderData and renderColumn
  useEffect(() => {
    if (displayData === `employees`) {
      setRenderData(allEmployees)
      setRenderColumn(employeesColumn)
    }
    if (displayData === `cars`) {
      setRenderData(allCars)
      setRenderColumn(carsColumn)
    }
    if (displayData === `offices`) {
      setRenderData([])
      setRenderColumn([])
    }
  }, [displayData, allEmployees, allCars])

  const employeesColumn = [
    {
      title: 'ID',
      dataIndex: 'employeeId',
      key: 'employeeId',
    },
    {
      title: 'Name',
      dataIndex: 'employeeName',
      key: 'employeeName',
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
    },
    {
      title: 'Salary',
      dataIndex: 'salary',
      key: 'salary',
    },
    {
      title: 'Joining Date',
      dataIndex: 'joiningDate',
      key: 'joiningDate',
      render: (dt) => moment(dt).format('DD-MM-YYYY'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (data, record) => (
        <span>
          {/* <p>{JSON.stringify(data)}</p> */}
          <Button
            type="outline"
            danger
            icon={<DeleteOutlined />}
            onClick={() => setVisible(data._id)}
          >
            Delete
          </Button>
        </span>
      ),
    },
  ]

  const carsColumn = [
    {
      title: 'Vehicle name',
      dataIndex: 'carName',
      key: 'carName',
    },
    {
      title: 'Vehicle Number',
      dataIndex: 'carNumber',
      key: 'carNumber',
    },
    {
      title: 'Tax token date',
      dataIndex: 'taxTokenDate',
      key: 'taxTokenDate',
      render: (dt) => moment(dt).format('DD-MM-YYYY'),
    },
    {
      title: 'Fitness date',
      dataIndex: 'fitnessDate',
      key: 'fitnessDate',
      render: (dt) => moment(dt).format('DD-MM-YYYY'),
    },
    {
      title: 'Route permit date',
      dataIndex: 'routePermitDate',
      key: 'routePermitDate',
      render: (dt) => moment(dt).format('DD-MM-YYYY'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (data, record) => (
        <span>
          {/* <p>{JSON.stringify(data)}</p> */}
          <Button
            type="outline"
            danger
            icon={<DeleteOutlined />}
            onClick={() => setVisible(data._id)}
          >
            Delete
          </Button>
        </span>
      ),
    },
  ]

  return (
    <AuthorizationLayout>
      <div style={styles.container}>
        <AddEmployee
          visible={addEmployeeModalOpen}
          setVisible={setAddEmployeeModalOpen}
        />
        <h2>Company Data</h2>
        <h1>{displayData === `cars` ? `VEHICLE` : displayData.toUpperCase()}</h1>
        <Select
          value={displayData}
          onChange={(value) => setDisplayData(value)}
          options={years}
        />
        <Modal
          title="Delete Confirmation"
          open={visible ? !!visible : false}
          onOk={() => {
            // any of them will work if data exists
            deleteEmployee(visible)
            deleteCar(visible)
          }}
          onCancel={() => setVisible(null)}
        >
          <p>Are you sure you want to delete this item?</p>
        </Modal>
        <Table
          dataSource={renderData}
          columns={renderColumn}
          // rowKey="_id"
          scroll={{ x: true }}
        />
      </div>
    </AuthorizationLayout>
  )
}

const styles = {
  container: {
    padding: 10,
    margin: 10,
    fontFamily: 'Times New Roman',
  },
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(CompanyData)

const years = [
  {
    value: 'employees',
    label: 'EMPLOYEES',
  },
  {
    value: 'cars',
    label: 'VEHICLE',
  },
  // {
  //   value: 'offices',
  //   label: 'offices',
  // },
]
