import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Space, Table, Button, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import ReactToPrint from 'react-to-print'

function ViewCostingsTable(props) {
  const { user } = props

  const [allCosts, setAllCosts] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const componentRef = useRef()
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              console.log(clearFilters())
              handleSearch(selectedKeys, confirm, dataIndex)
              clearFilters && handleReset(clearFilters)
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    // render: (text) => (searchedColumn === dataIndex ? text : text),
    // render: (text) => ,
  })

  const renderColumn = [
    // {
    //   title: 'ID',
    //   dataIndex: 'costId',
    //   key: 'costId',
    // },
    {
      title: 'Cost Type',
      dataIndex: 'costType',
      key: 'costType',
      filters: [
        {
          text: 'VEHICLE',
          value: 'cars',
        },
        {
          text: 'OFFICES',
          value: 'offices',
        },
        {
          text: 'EMPLOYEES',
          value: 'employees',
        },
      ],
      onFilter: (value, record) => record.costType.indexOf(value) === 0,
      filterSearch: true,
      render: (data, record) => (
        <span>
          <Space>
            <p>{data.toUpperCase()}</p>
          </Space>
        </span>
      ),
    },
    {
      title: 'Cost by',
      dataIndex: 'costBy',
      key: 'costBy',
      render: (data, record) => (
        <span>
          <Space>
            <p>
              {data.length > 0 ? (
                `${data[0]?.employeeName} - ${data[0].designation}`
              ) : (
                <span style={{ color: '#A52A2A' }}>No Data</span>
              )}
            </p>
          </Space>
        </span>
      ),
    },
    {
      title: 'For',
      // dataIndex: 'costType',
      key: 'costType',
      render: (data, record) => (
        <span>
          <Space>
            {data.carId && `${data.car[0].carNumber} - ${data.car[0].carName}`}
            {data.employeeId &&
              `${data.employee[0].employeeName} - ${data.employee[0].designation}`}
            {data.costType === 'offices' && 'Office'}
          </Space>
        </span>
      ),
    },
    {
      title: 'Taka',
      dataIndex: 'cost',
      key: 'cost',
      defaultSortOrder: '',
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: 'Purpose',
      // dataIndex: 'costPurpose',
      key: 'costPurpose',
      ...getColumnSearchProps('costPurpose'),
      render: (data, record) => (
        <span>
          {/* <p>{JSON.stringify(data)}</p> */}
          <Space>
            <p>{data.costPurpose}</p>
          </Space>
        </span>
      ),
      // Todo: if costType: employees then show employee name, if costType: cars then show car number, if office then just show "Office" string.
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (dt) => moment(dt).format('DD-MM-YYYY'),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      onFilter: (value, record) =>
        moment(record.createdAt).isSame(moment(value), 'day'),
    },
  ]

  const getAllCosts = async () => {
    console.log(`getAllCosts()`)
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}/api/user/getAllCosts`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Send the token in the Authorization header
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    )
    const data = await response.json()
    console.log(data.allCosts?.length)
    if (data.allCosts?.length === 0) {
      setAllCosts(data.allCosts)
    } else {
      // For test purpose only
      // let myArray = new Array(10000).fill(data.allCosts[0])
      // setAllCosts([...data.allCosts, ...myArray])
      setAllCosts([...data.allCosts])
    }
  }

  useEffect(() => {
    getAllCosts()
  }, [])

  return (
    <div style={{ marginTop: 20 }}>
      <ReactToPrint
        trigger={() => <button>Print</button>}
        content={() => componentRef.current}
      />
      {/* <p>Total data: {allCosts.length}</p> */}
      <Table
        bordered
        ref={componentRef}
        dataSource={allCosts}
        columns={renderColumn}
        // rowKey="_id"
        scroll={{ x: true }}
        title={() => (
          <>
            <p style={{ textAlign: 'center', margin: 0 }}>
              <b>CHATTAGRAM SHEBA SHANGSTHA</b>
            </p>
            <p style={{ textAlign: 'center', margin: 0 }}>List of costing</p>
            {/* <p style={{ margin: 0 }}>Costings. Total data: {allCosts.length}</p> */}
          </>
        )}
      />
    </div>
  )
}

const styles = {
  container: {
    padding: 10,
    margin: 10,
    fontFamily: 'Times New Roman',
  },
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(ViewCostingsTable)
