import React from 'react'
import axios from 'axios'
import {
  Input,
  Row,
  Col,
  Typography,
  Menu,
  Spin,
  message,
  Button,
  Select,
  Space,
} from 'antd'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'

import { loginAccount } from '../redux/actions/user'

function Login(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const [errResponse, setErrResponse] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [usersData, setUsersData] = React.useState([])
  const [form, setForm] = React.useState({
    email: location?.state?.email ? location?.state?.email : '',
  })

  React.useEffect(() => {}, [])

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => {
    setErrResponse('')
    dispatch(loginAccount(form, navigate, setErrResponse))
  }

  return (
    <Row style={{ height: '100vh', backgroundColor: '#fafefe' }}>
      <Col xs={24} sm={24} lg={12}>
        <div
          style={{
            height: '100%',
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // border: '1px solid blue',
          }}
        >
          <h1 style={{ marginBottom: 40 }}>Welcome to Sheba Shongstha</h1>

          {/* <div style={{ width: '45%' }}> */}
          <Row style={{ width: '100%', justifyContent: 'center' }}>
            <Col xs={24} lg={12} style={{ border: '1px solid white' }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <p style={{ color: '#000000d9' }}>Email</p>

                <Input
                  // placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  style={{ padding: 10 }}
                />

                <p style={{ color: '#000000d9' }}>Password</p>
                <Input.Password
                  // placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  style={{ padding: 10 }}
                />
              </Space>
              {/* </div> */}
            </Col>
          </Row>
          {errResponse && (
            <p style={{ color: 'red', marginTop: 10 }}>{errResponse}</p>
          )}
          <Button
            onClick={handleSubmit}
            type="primary"
            style={{
              margin: 10,
              height: 40,
              width: '30%',
              marginTop: 20,
            }}
          >
            Login
          </Button>
        </div>
      </Col>
    </Row>
  )
}

export default Login
