import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Modal, Select, Space, Input, Button, message, Popconfirm } from 'antd'

function AddCostModal(props) {
  const { visible, setVisible, user } = props
  const [selectedCostType, setSelectedCostType] = useState(null)
  const [allEmployees, setAllEmployees] = useState([])
  const [allCars, setAllCars] = useState([])
  const [costBy, setCostBy] = useState(null)
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [selectedCar, setSelectedCar] = useState(null)
  const [enteredCost, setEnteredCost] = useState(null)
  const [costPurpose, setcostPurpose] = useState('')
  const [confirmationVisible, setConfirmationVisible] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const getAllEmployees = async () => {
    console.log(`getAllEmployees()`)
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}/api/user/getAllEmployees`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Send the token in the Authorization header
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    )
    const data = await response.json()
    // console.log(data)
    const optionss = data.employees.map((employee) => ({
      label: `${employee.employeeName} (${employee.designation})`,
      value: employee._id,
    }))
    setAllEmployees(optionss)
  }

  const getAllCars = async () => {
    console.log(`getAllCars()`)
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}/api/user/getAllCars`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Send the token in the Authorization header
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    )
    const data = await response.json()
    // console.log(data)

    const optionss = data.carDatas.map((car) => ({
      label: `${car.carNumber} (${car.carName})`,
      value: car._id,
    }))
    setAllCars(optionss)
  }

  const handleCostSubmit = async () => {
    setConfirmationVisible(null)

    const data = {
      costType: selectedCostType,
      costBy,
      costPurpose,
      cost: enteredCost,
      employeeId: selectedEmployee, // if selectedCostType === 'employees'
      carId: selectedCar, // if selectedCostType === 'cars'
    }
    console.log(data)
    let isDataValid = null
    const propertiesToCheck = ['costType', 'costPurpose', 'cost']
    Object.keys(data).forEach((key) => {
      if (
        propertiesToCheck.includes(key) &&
        (data[key] === null || data[key] === '')
      ) {
        isDataValid =
          'Please add the followings properly: cost type, cost purpose and cost'
      }
    })

    if (typeof data.employeeId !== 'string' && data.costType === 'employees') {
      isDataValid = `Please select an employee`
    }
    if (typeof data.carId !== 'string' && data.costType === 'cars') {
      isDataValid = `Please select a vehicle`
    }
    if (!!isDataValid) {
      // your code
      setErrorMessage(isDataValid)
      return
    }

    axios
      .post(
        `${process.env.REACT_APP_SERVER}/api/user/createACost`,
        {
          ...data,
          cost: parseFloat(data.cost),
          addedById: user.user.data.user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${user.user.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response)
        message.success('A sccessfully')
        setErrorMessage('')
        setcostPurpose('')
        setSelectedEmployee(null)
        setSelectedCar(null)
        setEnteredCost(null)
        setVisible(false)
        props.getAllCosts()
      })
      .catch((error) => {
        console.error(error)
        message.error('Failed to add')
      })
  }

  const handleOk = () => {
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  useEffect(() => {
    getAllEmployees()
    getAllCars()
  }, [])

  return (
    <Modal
      title="Add Cost"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Space direction="vertical">
        <Select
          style={{ width: 170 }}
          placeholder="Select a cost type"
          value={selectedCostType}
          onChange={(value) => setSelectedCostType(value)}
          options={costTypes}
        />

        <Select
          style={{ width: 250 }}
          placeholder="Cost By"
          value={costBy}
          onChange={(value) => setCostBy(value)}
          options={allEmployees}
        />

        {selectedCostType === 'employees' && (
          <Select
            style={{ width: 250 }}
            placeholder="Select an employee"
            value={selectedEmployee}
            onChange={(value) => setSelectedEmployee(value)}
            options={allEmployees}
          />
        )}

        {selectedCostType === 'cars' && (
          <Select
            style={{ width: 250 }}
            placeholder="Select a vehicle"
            value={selectedCar}
            onChange={(value) => setSelectedCar(value)}
            options={allCars}
          />
        )}

        {selectedCostType && (
          <Space direction="vertical">
            <Input
              placeholder="Enter cost (Taka)"
              style={{ width: 250 }}
              type="number"
              value={enteredCost}
              onChange={(e) => setEnteredCost(e.target.value)}
            />
            <Input
              placeholder="Cost purpose"
              style={{ width: 250 }}
              value={costPurpose}
              onChange={(e) => setcostPurpose(e.target.value)}
            />
          </Space>
        )}

        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

        <Popconfirm
          title="Are you sure to add this costing?"
          onConfirm={() => handleCostSubmit()}
          onCancel={() => setConfirmationVisible(null)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" onClick={() => setConfirmationVisible(true)}>
            Submit
          </Button>
        </Popconfirm>
      </Space>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(AddCostModal)

const costTypes = [
  {
    value: 'employees',
    label: 'employees',
  },
  {
    value: 'cars',
    label: 'vehicle',
  },
  {
    value: 'offices',
    label: 'offices',
  },
]
