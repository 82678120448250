import React from 'react'
import { Modal, Form, Button, DatePicker, Input, message } from 'antd'
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'

function AddCarModal(props) {
  const { visible, setVisible, user } = props
  const navigate = useNavigate()

  const handleOk = () => {
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const [form] = Form.useForm()

  const onFinish = (values) => {
    console.log('Success:', values)
    console.log(user.user.token)

    // When submitting add the name of the user(or admin) who added this entry
    axios
      .post(
        `${process.env.REACT_APP_SERVER}/api/user/createACar`,
        {
          ...values,
          addedById: user.user.data.user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${user.user.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response)
        message.success('A sccessfully')
      })
      .catch((error) => {
        console.error(error)
        message.error('Failed to add')
      })
  }

  return (
    <Modal
      title="Add vehicle" // at backend its car
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          label="Vehicle Name"
          name="carName"
          rules={[{ required: true, message: 'Please input the vehicle name!' }]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Vehicle Number"
          name="carNumber"
          rules={[{ required: true, message: 'Please input the vehicle Number!' }]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tax Token Date"
          name="taxTokenDate"
          rules={[
            { required: true, message: 'Please input the Tax Token Date!' },
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Fitness date"
          name="fitnessDate"
          rules={[
            { required: true, message: 'Please input the Tax Token Date!' },
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Route permit date"
          name="routePermitDate"
          rules={[
            { required: true, message: 'Please input the Route permit date!' },
          ]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(AddCarModal)
