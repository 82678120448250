import axios from 'axios'
import { Typography, Menu, Spin, message, Button, Input, Select } from 'antd'

import {
  SET_USER,
  SET_IS_UNREAD_BOOLEAN,
  SET_NOTIFICATIONS,
  SET_IS_NOTIFICATION_BOOLEAN,
  SET_RECOMMENED_JOBS,
} from '../types'

export const signup = (form, navigate, setErrResponse) => {
  return async (dispatch, getState) => {
    try {
      console.log(form)
      // dispatch({ type: AUTH_LOADING, payload: true })

      const data = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER}/api/auth/signup`,
        data: form,
      })

      console.log(data.data)
      message.success(data.data.message)

      navigate('/verifyEmail', { state: { id: 1, email: form.email } })

      // dispatch({ type: AUTH_LOADING, payload: false })
    } catch (err) {
      // console.log('signup()  error')
      // console.log(err?.response?.data)
      // message.error('signup()  error')

      setErrResponse(
        'Please make sure you have added all the inputs correctly.'
      )
      // dispatch({ type: AUTH_LOADING, payload: false })
    }
  }
}

export const verifyEmail = (form, navigate, setErrResponse) => {
  return async (dispatch, getState) => {
    try {
      console.log(form)
      // dispatch({ type: AUTH_LOADING, payload: true })

      const data = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER}/api/auth/verifyEmail`,
        data: form,
      })

      console.log(data.data)
      message.success(data.data.message)

      navigate('/home')

      // dispatch({ type: AUTH_LOADING, payload: false })
      dispatch({ type: SET_USER, payload: data.data })
    } catch (err) {
      // console.log('verifyEmail()  error')
      // console.log(err)
      // message.error('verifyEmail()  error')

      setErrResponse(err?.response?.data?.message || 'Something went wrong')

      // dispatch({ type: AUTH_LOADING, payload: false })
    }
  }
}

export const loginAccount = (form, navigate, setErrResponse) => {
  return async (dispatch, getState) => {
    try {
      console.log(form)
      // dispatch({ type: AUTH_LOADING, payload: true })

      const data = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER}/api/auth/login`,
        data: form,
      })

      console.log(data.data)
      message.success(data.data.message)

      navigate('/home')

      // dispatch({ type: AUTH_LOADING, payload: false })
      dispatch({ type: SET_USER, payload: data.data })
    } catch (err) {
      // console.log('loginAccount()  error')
      // console.log(err.response.data.message)
      setErrResponse(err?.response?.data?.message || 'Something went wrong')
      // message.error('loginAccount()  error')

      // dispatch({ type: AUTH_LOADING, payload: false })
    }
  }
}

export const logoutAccount = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_USER, payload: null })
    } catch (err) {
      console.log(err)
      message.error('logoutAccount()  error')
    }
  }
}

export const isAnyUnreadForMe = () => {
  return async (dispatch, getState) => {
    try {
      const token = getState().user?.user?.token
      // dispatch({ type: AUTH_LOADING, payload: true })

      const data = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER}/api/user/isAnyUnreadForMe`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      // console.log(data.data)
      // message.success(JSON.stringify(data.data.doesUnreadExits))

      // dispatch({ type: AUTH_LOADING, payload: false })
      dispatch({
        type: SET_IS_UNREAD_BOOLEAN,
        payload: !!data.data.doesUnreadExits,
      })
    } catch (err) {
      console.log('isAnyUnreadForMe()  error')
      console.log(err)
      message.error('isAnyUnreadForMe()  error')

      // dispatch({ type: AUTH_LOADING, payload: false })
    }
  }
}

export const getMyNotification = () => {
  return async (dispatch, getState) => {
    try {
      const token = getState().user?.user?.token
      // dispatch({ type: AUTH_LOADING, payload: true })

      const data = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER}/api/user/myNotification`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      // console.log(data.data)
      // message.success(JSON.stringify(data.data.notifications))

      // dispatch({ type: AUTH_LOADING, payload: false })
      dispatch({
        type: SET_NOTIFICATIONS,
        payload: data.data.notifications,
      })

      // Check if any notification has not opened by user then set the dot badge (doesUnreadNotificationExits) to be true
      for (let ele of data.data.notifications) {
        if (!ele.openedByUser) {
          // console.log(ele.openedByUser)
          dispatch({
            type: SET_IS_NOTIFICATION_BOOLEAN,
            payload: true,
          })
          break
        }
        // console.log(ele.openedByUser)
      }
    } catch (err) {
      console.log('getMyNotification()  error')
      console.log(err)
      message.error('getMyNotification()  error')

      // dispatch({ type: AUTH_LOADING, payload: false })
    }
  }
}

export const getRecommendedJobs = () => {
  return async (dispatch, getState) => {
    try {
      const token = getState().user?.user?.token
      // dispatch({ type: AUTH_LOADING, payload: true })

      const data = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER}/api/user/recommenedJobs`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      console.log(data.data)
      // message.success(JSON.stringify(data.data.notifications))

      // dispatch({ type: AUTH_LOADING, payload: false })
      dispatch({
        type: SET_RECOMMENED_JOBS,
        payload: data.data.recommenedJobs,
      })
    } catch (err) {
      console.log('getMyNotification()  error')
      console.log(err)
      message.error('getMyNotification()  error')

      // dispatch({ type: AUTH_LOADING, payload: false })
    }
  }
}
