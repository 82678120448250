import React, { useState, useRef, useEffect } from 'react'
import {
  Typography,
  Menu,
  Spin,
  message,
  Button,
  BackTop,
  Table,
  Modal,
  Space,
  Input,
} from 'antd'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useNavigate,
  Link,
  useLocation,
} from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import ReactToPrint from 'react-to-print'
import {
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import AuthorizationLayout from '../components/layout/AuthorizationLayout.jsx'
import ViewResumeModal from '../components/Modal/ViewResumeModal'

import { logoutAccount } from '../redux/actions/user'

function Clients(props) {
  const { user } = props

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const categoryParamValue = searchParams.get('category')

  const [loading, setLoading] = React.useState(false)
  const [selectedType, setSelectedType] = React.useState(categoryParamValue)
  const [getClientsLoading, setGetClientsLoading] = React.useState(false)
  const [clientsData, setClientsData] = React.useState([])
  const [totalBillPerMonth, setTotalBillPerMonth] = React.useState(0)
  const [visible, setVisible] = useState(null)
  const [viewAgreement, setViewAgreement] = React.useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const componentRef = useRef()
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search Company Name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              console.log(clearFilters())
              handleSearch(selectedKeys, confirm, dataIndex)
              clearFilters && handleReset(clearFilters)
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    // render: (text) => (searchedColumn === dataIndex ? text : text),
    // render: (text) => ,
  })

  const columns = [
    // {
    //   title: 'Number',
    //   key: 'number',
    //   render: (text, record, index) => index + 1,
    // },
    {
      title: 'Number',
      key: 'fileNumber',
      dataIndex: 'fileNumber',
    },
    {
      title: 'ID',
      dataIndex: 'customerId',
      key: 'customerId',
      sorter: (a, b) => a.customerId - b.customerId,
    },
    {
      title: 'Client Type',
      dataIndex: 'clientType',
      key: 'clientType',
    },
    {
      title: 'Name',
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: (a, b) => {
        // console.log(a.companyName)
        return a.companyName.localeCompare(b.companyName)
      },
      ...getColumnSearchProps('companyName'),
    },
    {
      title: 'Bill per Month',
      dataIndex: 'billPerMonth',
      key: 'billPerMonth',
      sorter: (a, b) => parseFloat(a.billPerMonth) - parseFloat(b.billPerMonth),
      render: (value, record, index) => <p>{parseFloat(value)}</p>,
    },
    {
      title: 'Contact Number',
      dataIndex: 'contactNumber',
      key: 'contactNumber',
    },
    {
      title: 'Customer Address',
      dataIndex: 'customerAddress',
      key: 'customerAddress',
    },
    // {
    //   title: 'Company Representative Name',
    //   dataIndex: 'customerName',
    //   key: 'customerName',
    // },
    // {
    //   title: 'Start Date',
    //   dataIndex: 'startDate',
    //   key: 'startDate',
    //   render: (startDate) => moment(startDate).format('YYYY-MM-DD'),
    // },
    // {
    //   title: 'End Date',
    //   dataIndex: 'endDate',
    //   key: 'endDate',
    //   render: (endDate) => moment(endDate).format('YYYY-MM-DD'),
    // },
    // {
    //   title: 'Sheba Shongstha Representative',
    //   dataIndex: 'shebaRepresentative',
    //   key: 'shebaRepresentative',
    // },
    // {
    //   title: 'Created At',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render: (createdAt) => moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
    // },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          {/* <p>{JSON.stringify(text)}</p> */}
          <Space>
            <Button
              type="primary"
              ghost
              icon={<PrinterOutlined />}
              onClick={() => printPreview(record)}
            >
              Agreement
            </Button>
            {/* <Button
            type="primary"
            ghost
            icon={<PrinterOutlined />}
            onClick={() =>
              printUrl(
                `${process.env.REACT_APP_PUBLIC_URL}/agreement/${record._id}`
              )
            }
          >
            Print agreement
          </Button> */}

            {props.user.user.data.user.role === 's-admin' && (
              <Link to={`/clients/edit/${text._id}`}>
                <Button type="primary" ghost icon={<EditOutlined />}>
                  Edit
                </Button>
              </Link>
            )}

            <Button
              type="outline"
              danger
              icon={<DeleteOutlined />}
              onClick={() => showModal(text._id)}
            >
              Delete
            </Button>
          </Space>
        </span>
      ),
    },
  ]

  React.useEffect(() => {
    getClientData()
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'Maybe not customizeable, according to internet research.'
      event.returnValue = message // Standard for most browsers
      return message // For some older browsers
    }
    if (getClientsLoading) {
      // This will only work if user pressed win+r (if clicks browser reload icon this message doesn't popup anymore)
      window.addEventListener('beforeunload', handleBeforeUnload)
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [getClientsLoading])

  const getClientData = async () => {
    var config = {
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER}/api/user/getAllClients?calculateDue=false&selectedType=${selectedType}`,
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    }

    setGetClientsLoading(true)

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data))
        setClientsData(response.data.clients)
        // map over clients array and parseFloat billPerMonth and add it to a variable
        response.data.clients.map((client) => {
          setTotalBillPerMonth((prev) => prev + parseFloat(client.billPerMonth))
        })
        setGetClientsLoading(false)
      })
      .catch(function (error) {
        console.log(error)
        setGetClientsLoading(false)
      })
  }

  const printPreview = async (record) => {
    console.log(`printPreview()`)
    console.log(record._id)
    setViewAgreement(record._id)
  }

  const printAgreement = async () => {
    // // const url = 'http://localhost:3000/printtest.png'
    // const url = 'http://localhost:3000/agreement/abc'
    // const win = window.open(url, '_blank')
    // win.focus()
    // win.print()

    window.print()

    // window.open('http://localhost:3000/agreement/abc', '_blank', 'height=600,width=800,toolbar=0,location=0,menubar=0').print()

    return
    try {
      // Download the image using axios
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/printtest.png`,
        {
          responseType: 'arraybuffer',
        }
      )
      const imageData = response.data

      // Create a Blob from the image data
      const blob = new Blob([imageData], { type: 'image/jpeg' })

      // Create an object URL for the Blob
      const objectUrl = URL.createObjectURL(blob)
      // http://localhost:3000/agreement/abc

      // Open the object URL in a new window
      const printWindow = window.open(objectUrl, '_blank')

      // Wait for the window to load
      await new Promise((resolve) => {
        printWindow.onload = resolve
      })

      // Print the window
      printWindow.print()

      // Revoke the object URL
      URL.revokeObjectURL(objectUrl)
    } catch (error) {
      // setError(error)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const showModal = (_id) => {
    setVisible(_id)
  }

  const handleOk = () => {
    // Perform delete operation
    console.log(visible)
    setVisible(null)
    axios
      .delete(
        `${process.env.REACT_APP_SERVER}/api/user/deleteClient?_id=${visible}`,
        {
          headers: {
            Authorization: `Bearer ${user.user.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response)
        message.success('Deleted')
        getClientData()
      })
      .catch((error) => {
        console.error(error)
        message.error('Failed to...')
      })
  }

  const handleCancel = () => {
    setVisible(null)
  }

  function printUrl(url) {
    console.log(url)
    message.info('Please wait 2 seconds for agreement generation')
    const printFrame = document.getElementById('printFrame')
    printFrame.src = url
    printFrame.onload = () => {
      console.log('loaded')
      // printFrame.contentWindow.print()
      setTimeout(() => {
        printFrame.contentWindow.print()
      }, 2000) // wait for 5 seconds (5000 milliseconds)
    }
  }

  return (
    <AuthorizationLayout>
      <iframe id="printFrame" style={{ display: 'none' }}></iframe>
      <Modal
        title="Delete Confirmation"
        open={visible ? !!visible : false}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to delete this item?</p>
      </Modal>
      <ViewResumeModal
        viewAgreement={viewAgreement ? true : false}
        _id={viewAgreement}
        setViewAgreement={setViewAgreement}
        printUrl={printUrl}
      />

      <div style={{ padding: 10 }}>
        <h1>Clients</h1>{' '}
        <Link to="/clients/addNew">
          <Button type="primary">Add Client </Button>
        </Link>
        {/* <ReactToPrint
          trigger={() => <button>Print</button>}
          content={() => componentRef.current}
        /> */}
        <p style={{ margin: 10 }}>Total clients: {clientsData?.length}</p>
        <p style={{ margin: 10 }}>
          Collectable: <b>{capitalize(inWords(totalBillPerMonth))}</b>{' '}
          Bangladeshi Taka ({totalBillPerMonth})
        </p>
        <Button
          type={selectedType == 'hC' ? 'primary' : 'ghost'}
          onClick={() => {
            setSelectedType('hC')
            navigate(`/clients?category=hC`)
            window.location.reload()
          }}
        >
          Hospital & Clinic
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          type={selectedType != 'hC' ? 'primary' : 'ghost'}
          onClick={() => {
            setSelectedType('dL')
            navigate(`/clients?category=dL`)
            window.location.reload()
          }}
        >
          Diagnostic & Lab
        </Button>
        <Button></Button>
        <Table
          pagination={false}
          ref={componentRef}
          loading={getClientsLoading}
          dataSource={clientsData}
          columns={columns}
          rowKey="_id"
          scroll={{ x: true }}
        />
      </div>
    </AuthorizationLayout>
  )
}

var a = [
  '',
  'one ',
  'two ',
  'three ',
  'four ',
  'five ',
  'six ',
  'seven ',
  'eight ',
  'nine ',
  'ten ',
  'eleven ',
  'twelve ',
  'thirteen ',
  'fourteen ',
  'fifteen ',
  'sixteen ',
  'seventeen ',
  'eighteen ',
  'nineteen ',
]
var b = [
  '',
  '',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
]

function inWords(num) {
  var n
  if ((num = num.toString()).length > 9) return 'overflow'
  n = ('000000000' + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/)
  if (!n) return
  var str = ''
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore '
      : ''
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh '
      : ''
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand '
      : ''
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred '
      : ''
  str +=
    n[5] != 0
      ? (str != '' ? 'and ' : '') +
        (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) +
        'only '
      : ''
  return str
}
const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(Clients)
