import React, { useEffect, useState } from 'react'
import {
  Modal,
  Form,
  Button,
  DatePicker,
  Input,
  message,
  Tag,
  Space,
  Select,
  Popconfirm,
} from 'antd'
import axios from 'axios'
import moment from 'moment'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
const { Option } = Select

function ChangePaidStatus({ ...props }) {
  const { visible, setVisible, paidStatusData, getClientData } = props
  const token = props?.user?.user?.token

  const navigate = useNavigate()
  const [paidBills, setPaidBills] = useState([])
  const [selectedYear, setSelectedYear] = useState(getCurrentYearString())
  const [selectedMonth, setSelectedMonth] = useState()
  const [paidAmount, setPaidAmount] = useState(0)
  const [modalStatus, setModalStatus] = useState(false) // edit bill modal
  const [billToBeEdit, setBillToBeEdit] = useState({})
  const [allEmployees, setAllEmployees] = useState([])
  const [costBy, setCostBy] = useState(null)

  const handleOk = () => {
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const [form] = Form.useForm()

  // Get this particular client bills
  const getAllPaidMonthsAndYear = async (clientId) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/user/getAllPaidMonthsAndYear?clientId=${clientId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      console.log(res.data.allPaidBills)
      setPaidBills(res.data.allPaidBills)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`)
    setSelectedYear(value)
  }

  //
  const makeBillPaid = async (clientId) => {
    if (!selectedMonth) {
      message.error('Please select a month')
      return
    }

    if (paidAmount <= 0 || !Number.isInteger(paidAmount)) {
      message.error('Please enter a valid amount.')
      return
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/user/makeBillPaid`,
        {
          clientId: clientId,
          monthToBePaid: selectedMonth,
          year: `${selectedYear}`,
          paidInput: paidAmount,
          costBy,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      console.log(res.data)
      message.success('Bill status set to paid.')
      getAllPaidMonthsAndYear(paidStatusData._id)
      getClientData()
      handleOk()
    } catch (error) {
      console.log(error)
      // message.error('Something went wrong.')
      message.error(error.response?.data?.message)
    }
  }

  // For edit bill modal
  const updateAPaidBill = async (newV, billToBeEdit) => {
    // converting string into number and if its failed to convert then return from this function
    if (isNaN(+newV)) {
      console.log('Return')
      return
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/user/updateMakeBillPaid`,
        {
          editAbleDoc_id: billToBeEdit._id,
          clientId: billToBeEdit.clientId,
          monthToBePaid: billToBeEdit.monthToBePaid,
          year: `${billToBeEdit.year}`,
          paidInput: +newV,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      console.log(res.data)
      message.success('Bill status updated.')
      getAllPaidMonthsAndYear(paidStatusData._id)
      getClientData()
    } catch (error) {
      console.log(error)
      // message.error('Something went wrong.')
      message.error(error.response?.data?.message)
    }
    setModalStatus(false)
  }

  // For edit bill modal
  const deleteABill = async (_id) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_SERVER}/api/user/deleteAPaidBill?editAbleDoc_id=${_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      console.log(res.data)
      message.success('Bill deleted successfully.')
      getAllPaidMonthsAndYear(paidStatusData._id)
      getClientData()
    } catch (error) {
      console.log(error)
      // message.error('Something went wrong.')
      message.error(error.response?.data?.message)
    }
    setModalStatus(false)
  }

  const getAllEmployees = async () => {
    console.log(`getAllEmployees()`)
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}/api/user/getAllEmployees`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Send the token in the Authorization header
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const data = await response.json()
    // console.log(data)
    const optionss = data.employees.map((employee) => ({
      label: `${employee.employeeName} (${employee.designation})`,
      value: employee._id,
    }))
    setAllEmployees(optionss)
  }

  useEffect(() => {
    getAllPaidMonthsAndYear(paidStatusData._id)
    // console.log(paidStatusData)
    // console.log(paidStatusData._id)
    getAllEmployees()
  }, [])

  return (
    <Modal
      title="Udate Bill Status of months"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose={true}
    >
      {/* Edit or Delete a paid bill modal */}
      <Modal
        style={{ zIndex: 20 }}
        title="Edit paid bill"
        open={modalStatus}
        onOk={() => setModalStatus(false)}
        onCancel={() => setModalStatus(false)}
        footer={null}
        destroyOnClose={true}
      >
        <p>
          For{' '}
          <span style={{ fontWeight: 'bolder' }}>
            {billToBeEdit.monthToBePaid} - {billToBeEdit.year}
          </span>
        </p>
        <Space direction="vertical">
          <Input.Search
            placeholder="Update the value"
            allowClear
            enterButton="Update"
            onSearch={(newV) => updateAPaidBill(newV, billToBeEdit)}
            defaultValue={billToBeEdit.paidAmount}
          />
          <Space>
            <p>
              If you are facing with udpating the value, you can{' '}
              <Button
                size="small"
                ghost
                type="danger"
                onClick={() => deleteABill(billToBeEdit._id)}
              >
                Delete
              </Button>{' '}
              this entry permanently{' '}
            </p>
          </Space>
        </Space>
        {/* <p>{JSON.stringify(billToBeEdit)}</p> */}
      </Modal>
      <p>
        <b>Company name:</b> {paidStatusData.companyName}
      </p>
      <p>
        <b>Client name:</b> {paidStatusData.customerName}
      </p>
      <p>
        <b>Address:</b> {paidStatusData.customerAddress}
      </p>
      <p>
        <b>Bill per month:</b> {paidStatusData.billPerMonth}
      </p>

      <Select
        style={{ width: 270 }}
        placeholder="View paid bills"
        // value={selectedCostType}
        // onChange={(value) => setSelectedCostType(value)}
      >
        {paidBills.map((item) => (
          <Option value={item.year} disabled>
            {item.monthToBePaid} {item.year} - {item.paidAmount} TK <br /> (
            {moment(item.createdAt).format('DD-MM-YYYY')})
            {props.user.user.data.user.role === 's-admin' && (
              <Button
                size="small"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  console.log(item._id)
                  setModalStatus(true)
                  setBillToBeEdit(item)
                }}
              >
                Edit
              </Button>
            )}
          </Option>
        ))}
      </Select>

      <p style={{ marginTop: 20, marginBottom: 2 }}>
        Make paid by choosing Year and Month
      </p>
      <p style={{ fontSize: 12 }}>
        Billing starts from:{' '}
        <span style={{ color: 'blue' }}>
          {moment(paidStatusData.startDate).format('MMMM YYYY')}
        </span>
      </p>
      <Select
        // defaultValue={selectedYear}
        value={selectedYear}
        onChange={handleChange}
        options={years}
      />

      <Select
        style={{ marginLeft: 5, width: 250 }}
        placeholder="Collected By"
        value={costBy}
        onChange={(value) => setCostBy(value)}
        options={allEmployees}
      />
      <br />
      <br />
      <Space style={{ flexWrap: 'wrap' }}>
        {months.map((month, index) => (
          <Tag
            key={index}
            style={{
              cursor: 'pointer',
            }}
            color={month === selectedMonth ? '#2a5c59' : ''}
            onClick={() => setSelectedMonth(month)}
          >
            {month}
          </Tag>
        ))}
      </Space>
      <Input
        // add extra margin
        style={{ marginTop: 30 }}
        type="text"
        placeholder="Enter paid amount"
        onChange={(e) => setPaidAmount(parseInt(e.target.value))}
      />
      <Popconfirm
        title="Are you sure to make this bill paid?"
        onConfirm={() => makeBillPaid(paidStatusData._id)}
        // onCancel={cancel}
        okText="Yes"
        cancelText="No"
      >
        <Button
          block
          style={{ marginTop: 30, marginBottom: '10px' }}
          type="primary"
        >
          Make Paid
        </Button>
      </Popconfirm>
    </Modal>
  )
}

const years = [
  {
    value: '2021',
    label: '2021',
  },
  {
    value: '2022',
    label: '2022',
  },
  {
    value: '2023',
    label: '2023',
  },
  {
    value: '2024',
    label: '2024',
  },
]

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

function getCurrentMonthString() {
  const month = new Date().getMonth()
  const monthString = months[month]
  return monthString
}

function getCurrentYearString() {
  const year = new Date().getFullYear()
  return year
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(ChangePaidStatus)
