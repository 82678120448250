import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, connect } from 'react-redux'
import {
  Button,
  Modal,
  Checkbox,
  message,
  Form,
  Input,
  InputNumber,
  Select,
  notification,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

function ViewResumeModal(props) {
  const { viewAgreement, setViewAgreement, printUrl, _id } = props
  const token = props?.user?.user?.token
  console.log('View modal')
  console.log(`${process.env.REACT_APP_PUBLIC_URL}/agreement?_id=${_id}`)

  const handleOk = () => {
    setViewAgreement(false)
  }

  const handleCancel = () => {
    setViewAgreement(false)
  }

  return (
    <Modal
      title="Agreement preview"
      maskClosable={false}
      open={viewAgreement}
      onOk={handleOk}
      onCancel={handleCancel}
      width={690}
    >
      <Button
        type="primary"
        onClick={() => {
          printUrl(`${process.env.REACT_APP_PUBLIC_URL}/agreement/${_id}`)
        }}
      >
        Print
      </Button>
      <br />
      <br />
      <iframe
        src={`${process.env.REACT_APP_PUBLIC_URL}/agreement/${_id}`}
        height="1200px"
        width={'100%'}
        title="Resume viewer"
      ></iframe>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps, {})(ViewResumeModal)
