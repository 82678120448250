import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useDispatch, connect } from 'react-redux'
import {
  Button,
  Modal,
  Checkbox,
  message,
  Form,
  Input,
  InputNumber,
  Select,
  notification,
  Tag,
  Space,
} from 'antd'
import moment from 'moment'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import ViewBillingPaper from '../../pages/ViewBillingPaper'

function BillingModalOfACompany(props) {
  const { visible, setVisible, selectedClient } = props
  const token = props?.user?.user?.token
  const iframeRef = useRef(null)
  // console.log(getCurrentMonthString())
  // console.log('Month above')
  const [selectedYear, setSelectedYear] = useState(getCurrentYearString())
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthString())
  const [billData, setBillData] = useState({})
  const [generateBill, setGenerateBill] = useState(false)
  const [billRemarks, setBillRemarks] = useState('')
  const [customDate, setCustomDate] = useState(
    moment().add(1, 'day').format('DD-MM-YYYY')
  )

  // console.log(`${process.env.REACT_APP_PUBLIC_URL}/agreement?_id=${_id}`)

  const handleOk = () => {
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
    setSelectedYear(getCurrentYearString())
    setSelectedMonth(getCurrentMonthString())
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`)
    setSelectedYear(value)
  }

  const handleGenerateBill = async () => {
    console.log('Generate Bill')
    console.log(selectedClient)
    console.log(selectedYear)
    console.log(selectedMonth)
    // const data = {
    //   clientId: selectedClient._id,
    //   billGenerationMonth: selectedMonth,
    //   billGenerationYear: `${selectedYear}`,
    // }
    // const response = await axios.post(
    //   `${process.env.REACT_APP_SERVER}/api/user/generateBill`,
    //   data,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // )
    // console.log(response.data)
    // setBillData(response.data)
    setGenerateBill(true)
  }

  function printUrl(url) {
    console.log(url)
    const printFrame = document.getElementById('printFrame')
    printFrame.src = url
    printFrame.onload = () => {
      // console.log('loaded')
      // message.info('Please wait 6 seconds for bill generation')

      // Old method: which really not sure if the data is ready to print, just delying and hoping the data is ready
      // printFrame.contentWindow.print()
      // setTimeout(() => {
      //   printFrame.contentWindow.print()
      // }, 6000) // wait for 5 seconds (5000 milliseconds)

      // New method: checking at the localStorage to confirm that the data is retrieved from backend so show the print modal print window at browser
      const intervalOne = setInterval(() => {
        message.info('Please wait...')
        message.destroy()
        let foundGeneratedBill = localStorage.getItem('generatedBill')
        foundGeneratedBill = JSON.parse(foundGeneratedBill)
        if (foundGeneratedBill?.billNo > 0) {
          console.log('Bill really found!')
          printFrame.contentWindow.print()
          localStorage.removeItem('generatedBill')
          clearInterval(intervalOne)
        }
      }, 500)
    }
  }

  let addRemarks = () => {
    if (billRemarks == null) {
      setBillRemarks('')
    }
    iframeRef.current.contentWindow.postMessage({ billRemarks }, '*')
  }

  return (
    <Modal
      title="Billing Modal"
      maskClosable={false}
      open={visible}
      // open={true}
      onOk={handleOk}
      onCancel={handleCancel}
      width={690}
    >
      <h3>{selectedClient?.companyName}</h3>
      <p>
        {selectedClient?.customerAddress}, {selectedClient?.contactNumber}
      </p>

      <Input.Search
        value={billRemarks}
        onChange={(evt) => {
          // alert(evt.target.value)
          setBillRemarks(evt.target.value)
        }}
        placeholder="Remarks (Optional)"
        enterButton="Update"
        allowClear
        onSearch={addRemarks}
      />

      <p style={{ fontSize: 12, marginTop: 5 }}>
        Billing starts from:{' '}
        <span style={{ color: 'blue' }}>
          {moment(selectedClient?.startDate).format('MMMM YYYY')}
        </span>
      </p>

      <div style={{ display: 'flex' }}>
        <Select
          // defaultValue={selectedYear}
          value={selectedYear}
          onChange={handleChange}
          options={years}
        />

        <Input
          style={{ width: 300, marginLeft: 10 }}
          placeholder="Custom Date (Optional)"
          onChange={(val) => setCustomDate(val.target.value)}
        />
      </div>
      <br />
      <br />
      <Space style={{ flexWrap: 'wrap' }}>
        {months.map((month, index) => (
          <Tag
            key={index}
            style={{
              cursor: 'pointer',
            }}
            color={month === selectedMonth ? '#2a5c59' : ''}
            onClick={() => setSelectedMonth(month)}
          >
            {month}
          </Tag>
        ))}
      </Space>
      <br />
      <br />
      {/* {JSON.stringify(billData)} */}
      {/* <Button type="primary" block onClick={handleGenerateBill}>
        Generate Bill
      </Button>
  */}
      <Button
        block
        style={{ marginBottom: '10px' }}
        type="primary"
        onClick={() => {
          printUrl(
            `${process.env.REACT_APP_PUBLIC_URL}/bill/${selectedClient?._id}/${selectedMonth}/${selectedYear}?billR=${billRemarks}&customDate=${customDate}`
          )
        }}
      >
        Print
      </Button>
      <iframe id="printFrame" style={{ display: 'none' }}></iframe>
      <iframe
        src={`${process.env.REACT_APP_PUBLIC_URL}/bill/${selectedClient?._id}/${selectedMonth}/${selectedYear}?customDate=${customDate}`}
        height="900px"
        width={'100%'}
        // title="Resume viewer"
        ref={iframeRef}
      ></iframe>
    </Modal>
  )
}

const years = [
  {
    value: '2021',
    label: '2021',
  },
  {
    value: '2022',
    label: '2022',
  },
  {
    value: '2023',
    label: '2023',
  },
  {
    value: '2024',
    label: '2024',
  },
]

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps, {})(BillingModalOfACompany)

function getCurrentMonthString() {
  const month = new Date().getMonth()
  const monthString = months[month]
  return monthString
}

function getCurrentYearString() {
  const year = new Date().getFullYear()
  return year
}
