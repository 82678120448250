import React from 'react'
import { Typography, Menu, Spin, message, Button, BackTop, Result } from 'antd'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useNavigate,
} from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import axios from 'axios'
import AuthorizationLayout from '../components/layout/AuthorizationLayout.jsx'

import { logoutAccount } from '../redux/actions/user'

// function to download zip file
const downloadZipFile = async () => {
  try {
    // send GET request to download route on server
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/user/downloadDb`,
      { responseType: 'blob' }
    )

    // create a URL object from the response data
    const url = window.URL.createObjectURL(new Blob([response.data]))

    // create a temporary link to download the file
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'mydata.zip')
    document.body.appendChild(link)
    link.click()

    // cleanup temporary URL object and link
    link.parentNode.removeChild(link)
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error(error)
  }
}

function MyProfile(props) {
  const { user } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)
  const [usersData, setUsersData] = React.useState([])
  const [serverDown, setServerDown] = React.useState(false)
  const [siteContent, setSiteContent] = React.useState({})

  React.useEffect(() => {}, [])

  // role can be either: n-user, s-admin

  return (
    <AuthorizationLayout>
      <div
        style={{
          height: `100vh`,
          padding: 20,
        }}
      >
        <h1>My profile</h1>
        <p>
          Type of this logged in user:{' '}
          <b>
            {user.user.data.user.role === 's-admin' && 'Super Admin'}
            {user.user.data.user.role === 'n-user' && 'Normal User'}
          </b>
        </p>
        <p>Email: {user.user.data.user.email}</p>
        {/* {user.user.data.user.role === 's-admin' && (
          <Button onClick={() => downloadZipFile()} style={{ margin: 10 }}>
            Download database
          </Button>
        )}   */}
        <Button danger onClick={() => dispatch(logoutAccount())}>
          Logout
        </Button>
      </div>
    </AuthorizationLayout>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(MyProfile)
