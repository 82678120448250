import { SET_SOCKET, TOOGLE_LIVE_NOTIFICATION } from '../types'

const initialState = {
  socket: null,
  showLiveMessageNotification: true, // currently using this flag for showing message notification in routes other than /inbox  & /inbox/*
}

export default function socket(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SET_SOCKET:
      return { ...state, socket: payload }
    case TOOGLE_LIVE_NOTIFICATION:
      return { ...state, showLiveMessageNotification: payload }
    default:
      return state
  }
}
