import {
  SET_USER,
  SET_IS_UNREAD_BOOLEAN,
  SET_NOTIFICATIONS,
  SET_IS_NOTIFICATION_BOOLEAN,
  SET_RECOMMENED_JOBS,
} from '../types'

const user = localStorage.getItem('user')

const initialState = {
  user: JSON.parse(user),
  doesUnreadExits: false,
  notifications: [],
  doesUnreadNotificationExits: false,
  recommenedJobs: [],
}

export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SET_USER:
      localStorage.setItem('user', JSON.stringify(payload))
      return { ...state, user: payload }
    case SET_IS_UNREAD_BOOLEAN:
      return { ...state, doesUnreadExits: payload }
    case SET_NOTIFICATIONS:
      return { ...state, notifications: payload }
    case SET_IS_NOTIFICATION_BOOLEAN:
      return { ...state, doesUnreadNotificationExits: payload }
    case SET_RECOMMENED_JOBS:
      return { ...state, recommenedJobs: payload }
    default:
      return state
  }
}
