import React, { useEffect, useState } from 'react'
import {
  Layout,
  Menu,
  Avatar,
  Row,
  Col,
  Typography,
  Badge,
  Button,
  Dropdown,
} from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  WechatOutlined,
  QqOutlined,
  MessageOutlined,
  UserOutlined,
  FileSearchOutlined,
  BellOutlined,
  ProfileOutlined,
  TeamOutlined,
  BankOutlined,
  LogoutOutlined,
  DollarOutlined,
} from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logoutAccount } from '../../redux/actions/user'

const userImg = `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
const { Content, Header, Sider } = Layout
const { Text, Paragraph } = Typography
const initialSize = {
  width: window.innerWidth,
}
const allowedUsers = [
  '6285d46a90c2046764b25cc2', // local kml
  '625c4c2dab2f53f6b3fac8c6', // remote
  '621ca5734eb3796fc54e1314', // remote kml
]

const AuthorizationLayout = (props) => {
  const { auth, socket, user } = props

  const [screen, setScreen] = useState(initialSize.width)

  const parseJwt = () => {
    try {
      const encoded = JSON.parse(atob(auth.user.token.split('.')[1]))
      return encoded
    } catch (e) {
      return console.log(e)
    }
  }

  const [collapsed, setCollapsed] = useState(() => {
    const showCollapsed = localStorage.getItem('collapsed')
    return JSON.parse(showCollapsed) ?? false
  })
  const [currentkey, setCurrentkey] = useState(null)
  const [unreadNotification, setUnreadNotification] = useState(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const toggle = () => {
    setCollapsed(!collapsed)
  }
  const handleClick = (e) => {
    setCurrentkey(e.key)
  }
  useEffect(() => {
    localStorage.setItem('collapsed', JSON.stringify(collapsed))
  }, [collapsed])

  useEffect(() => {
    if (location.pathname === '/dashboard') setCurrentkey('1')
    if (location.pathname === '/consultants') setCurrentkey('2')
    if (location.pathname === '/inbox') setCurrentkey('3')
    if (location.pathname === '/users/list') setCurrentkey('4')
    if (location.pathname === '/my-profile') setCurrentkey('5')
  }, [])

  useEffect(() => {
    const handlar = () => {
      setScreen(window.innerWidth)
    }
    window.addEventListener('resize', handlar)
    if (screen >= 768) {
      // on large screen
    } else {
      setCollapsed(true)
    }
    return () => {
      window.removeEventListener('resize', handlar)
    }
  }, [screen])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        id="authorized"
        breakpoint="lg"
        collapsedWidth="65"
        trigger={null} //
        collapsible
        collapsed={collapsed} // state value (Can't initialize this value if auto collapse is required)
        // style={{ backgroundColor: '#3E9C58' }}
        width="230"
        onBreakpoint={(broken) => {
          // console.log(broken)
        }}
        onCollapse={(collapsed, type) => {}}
      >
        <div style={styles.logoContainer}>
          <div
            style={{ display: 'flex', alignItems: 'center', height: '62px' }}
          >
            <h2 style={{ color: '#fff' }}>Sheba CRM</h2>
          </div>
        </div>
        <Menu
          theme="dark"
          // mode="inline"
          onClick={handleClick}
          selectedKeys={[currentkey]}
          //defaultSelectedKeys={['1']} // TODO: try to make it dynamic later, if needed.
          // style={{ color: '#fff' }}
        >
          <Menu.Item
            key="1"
            icon={<PieChartOutlined />}
            // style={{ color: '#fff' }}
            // onClick={() => navigate('/dashboard')}
          >
            <Link to="/dashboard">
              <span style={{ fontWeight: 'normal' }}>Dashboard</span>
            </Link>
          </Menu.Item>

          {/* {auth?.user?.user?.role === 'user' && (
            <Menu.Item
              key="41"
              icon={<QqOutlined />}
              style={{ color: '#fff' }}
              // onClick={() => navigate('/users/list')}
              onClick={() => alert('Not functional yet!')}
            >
              <Link to="/users/list">
                <span style={{ color: '#fff', fontWeight: 'normal' }}>
                  Anonymous Conversation
                </span>
              </Link>
            </Menu.Item>
          )} */}

          <Menu.Item
            key="2"
            icon={<TeamOutlined />}
            style={{ color: '#fff' }}
            onClick={() => navigate('/clients')}
          >
            <span style={{ color: '#fff', fontWeight: 'normal' }}>Clients</span>
          </Menu.Item>

          <Menu.Item
            key="3"
            icon={<ProfileOutlined />}
            style={{ color: '#fff' }}
            onClick={() => navigate('/billing')}
          >
            <span style={{ color: '#fff', fontWeight: 'normal' }}>Billing</span>
          </Menu.Item>

          <Menu.Item
            key="4"
            icon={<DollarOutlined />}
            style={{ color: '#fff' }}
            onClick={() => navigate('/costing')}
          >
            <span style={{ color: '#fff', fontWeight: 'normal' }}>Costing</span>
          </Menu.Item>

          <Menu.Item
            key="5"
            icon={<BankOutlined />}
            style={{ color: '#fff' }}
            onClick={() => navigate('/company-data')}
          >
            <span style={{ color: '#fff', fontWeight: 'normal' }}>
              Company Data
            </span>
          </Menu.Item>

          <Menu.Item
            key="111"
            icon={<UserOutlined />}
            style={{ color: '#fff' }}
            onClick={() => navigate('/my-profile')}
          >
            <span style={{ color: '#fff', fontWeight: 'normal' }}>Profile</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 10,
            backgroundColor: '#fff',
            boxShadow: '3px 1px 5px -2px #000000',
            zIndex: 1,
            lineHeight: 'normal',
            height: `auto`,
          }}
        >
          <Row
            style={{
              width: '100%',
              // border: '1px solid red',
            }}
          >
            <Col span={18} style={{ display: 'flex', alignItems: 'center' }}>
              {collapsed ? (
                <MenuUnfoldOutlined
                  onClick={toggle}
                  style={{ color: '#0FA958' }}
                />
              ) : (
                <MenuFoldOutlined
                  onClick={toggle}
                  style={{ color: '#0FA958' }}
                />
              )}
            </Col>
            <Col
              span={6}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'auto',
                  marginRight: 3,
                  cursor: 'pointer',
                }}
              >
                {/* <SearchOutlined style={{ fontSize: 20 }} /> */}
              </div>
            </Col>
          </Row>
        </Header>
        <Content className="maincontent" style={{ backgroundColor: '#fff' }}>
          {props.children}
        </Content>
      </Layout>
    </Layout>
  )
}

const styles = {
  logoCircle: {
    display: 'flex',
    // color: '#fff',
    borderRadius: '50%',
    padding: 5,
    // background: '#3E9C58',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    width: '100%',
    // backgroundColor: '#F3F3F3',
    padding: '4px 10px',
  },
}

const mapStateToProps = (state, ownState) => {
  return {
    auth: state.auth,
    socket: state.socket.socket,
    user: state.user,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationLayout)
