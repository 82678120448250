import React from 'react'
import { Typography, Menu, Spin, message, Button, BackTop, Result } from 'antd'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useNavigate,
} from 'react-router-dom'

function RedirectOnly() {
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)
  const [usersData, setUsersData] = React.useState([])
  const [landingPageData, setLandingPageData] = React.useState({})
  const [serverDown, setServerDown] = React.useState(false)
  const [siteContent, setSiteContent] = React.useState({})

  React.useEffect(() => {
    navigate('/dashboard')
  }, [])

  return <div style={{}}></div>
}

export default RedirectOnly
