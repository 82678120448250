import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useNavigate,
  Link,
  useLocation,
} from 'react-router-dom'
import {
  Input,
  Typography,
  List,
  Tooltip,
  Button,
  Checkbox,
  Divider,
  message,
  Modal,
} from 'antd'
import axios from 'axios'
import cachify from '../cachifyjs'
import AuthorizationLayout from '../components/layout/AuthorizationLayout'
import BillingModalOfACompany from '../components/Modal/BillingModalOfACompany'

function handleError(error) {
  //handle if any error occurs during data refreshing on api call (ex: authentication error)
}
function handleResponse(response) {
  //handle api response here
  // console.log(response)
}

const CheckboxGroup = Checkbox.Group
const plainOptions = ['Hospital', 'Clinic', 'Diagnostic Centre', 'Lab']
const defaultCheckedList = ['Hospital', 'Clinic', 'Diagnostic Centre', 'Lab']

function Billings(props) {
  const location = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)
  const categoryParamValue = searchParams.get('category')

  const [selectedType, setSelectedType] = React.useState(categoryParamValue)
  const [clientsWithoutFilter, setClientsWithoutFilter] = useState([]) // Initial data without checkbox filter
  const [clients, setClients] = useState([])
  const [billingModalVisible, setBillingModalVisible] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false) // print all modal
  const [modalCheckboxOptions, setModalCheckboxOptions] = useState([]) // all clients
  const [modalCheckboxOptionsSelected, setModalCheckboxOptionsSelected] =
    useState([]) // only selected from checkbox, initally all checked

  // Below all related to checkbox
  const [checkedList, setCheckedList] = useState(defaultCheckedList)
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)
  const [customDate, setCustomDate] = useState(
    moment().add(1, 'day').format('DD-MM-YYYY')
  )
  const onChange = (list) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < plainOptions.length)
    setCheckAll(list.length === plainOptions.length)
  }
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }
  // Up all related to checkbox

  let timeout

  function handleSearch(value) {
    clearTimeout(timeout) // if typed within half seconds then dont update the track at DB (to avoid redundant update)
    // Update details of a track object
    timeout = setTimeout(async () => {
      console.log(value)
      // make a post requst to /searchClients route at localhost:5002

      const axiosConfig = {
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER}/api/user/searchClients`,
        data: {
          search: value,
          selectedType,
        },
        headers: {
          Authorization: `Bearer ${props.user.user.token}`,
        },
      }
      // configuration for caching
      const cacheConfig = {
        key: `/api/user/searchClients`, //your own choice, recommended to keep it similar to your api uri
        errorCallback: handleError,
        lifetime: '1h',
        encryption: {
          secretKey: '311',
        },
        postSync: {
          callback: handleResponse,
          syncTimeout: 1, //default (ms)
          syncInterval: '3h', //with time specifier
        },
      }

      let response = await cachify(axiosConfig, cacheConfig)
      // console.log(response.data)
      setClients(response.data.clients)
      setClientsWithoutFilter(response.data.clients)

      const checkboxOptions = response.data.clients.map((el, idx) => {
        return {
          label: `${el.customerId} - ${el.clientType} - ${el.companyName}`,
          value: el._id,
        }
      })
      setModalCheckboxOptions(checkboxOptions)
      setModalCheckboxOptionsSelected(checkboxOptions)

      // * This below commented section is same as the cachify one (please update this section if you make any updated after the cachify update)
      // * doing it second time for live updated insead of cache update, because of the state update during searches.
      await axios(axiosConfig)
        .then((response) => {
          // console.log(response.data)
          // console.log(response.data.clients)
          setClients(response.data.clients)
          setClientsWithoutFilter(response.data.clients)

          const checkboxOptions = response.data.clients.map((el, idx) => {
            return {
              label: `${el.customerId} - ${el.clientType} - ${el.companyName}`,
              value: el._id,
            }
          })
          setModalCheckboxOptions(checkboxOptions)
          setModalCheckboxOptionsSelected(checkboxOptions)
        })
        .catch((error) => {
          console.error(error)
        })

      // }, 0)
    }, 500)
  }

  const handleClick = (item) => {
    console.log(item)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onPrintAllChange = async (itemsToPrint) => {
    // console.log(`onPrintAllChange()`)
    // console.log(itemsToPrint) // selected item from the options (array of strings)
    if (itemsToPrint) {
      localStorage.setItem('itemsToPrint', itemsToPrint) // Important (keeping the id to use them "ViewBillingPaperBulk.js")
      // To show visual setting up the "setModalCheckboxOptionsSelected()"
      const tempOptions = modalCheckboxOptions.filter((el) => {
        return itemsToPrint.includes(el.value) // el.value is mainly el._id of main data
      })
      setModalCheckboxOptionsSelected(tempOptions)
      return
    }

    if (!itemsToPrint) {
      // this below condition if for: initally if nothing is disseelcted then need to setup the localStorage with all values to use at bulk.js
      let ifStored = localStorage.getItem('itemsToPrint')
      // console.log(ifStored)
      if (!ifStored) {
        const iTP = clients.slice(0, clients.length).map((item) => item._id)
        localStorage.setItem('itemsToPrint', iTP) // Important (keeping the id to use them "ViewBillingPaperBulk.js")
      }

      // return
      // * When print button clicked from Modal
      // console.log(`http://localhost:3000/bulkBillPrint/February/2023`)
      setTimeout(() => {
        printUrl(
          `${
            process.env.REACT_APP_PUBLIC_URL
          }/bulkBillPrint/${getCurrentMonthString()}/${getCurrentYearString()}?customDate=${customDate}`
        )
      }, 1000)
    }
  }

  useEffect(() => {
    handleSearch('') // Doing this to get all the clients (temporarily)
  }, [])

  // Checkbox filtering
  useEffect(() => {
    if (checkedList.length === 0) {
      setClients(clientsWithoutFilter)
      return
    }
    const filteredData = clientsWithoutFilter.filter((item) =>
      checkedList.includes(item.clientType)
    )
    setClients(filteredData)
  }, [checkedList])

  function printUrl(url) {
    message.info(
      <div>
        Please wait, it may take a while...{' '}
        <Button
          size="small"
          danger
          onClick={() => {
            message.destroy()
          }}
        >
          Close
        </Button>
      </div>,
      60000
    )
    const printFrame = document.getElementById('printFrame')
    printFrame.src = url
    printFrame.onload = () => {
      console.log('loaded')
      // printFrame.contentWindow.print()
      setTimeout(() => {
        printFrame.contentWindow.print()
      }, 60000) // wait for 5 seconds (5000 milliseconds)
    }
  }

  return (
    <AuthorizationLayout>
      <div style={styles.container}>
        <iframe id="printFrame" style={{ display: 'none' }}></iframe>

        <BillingModalOfACompany
          visible={billingModalVisible}
          setVisible={setBillingModalVisible}
          selectedClient={selectedClient}
        />

        {isModalOpen && (
          <Modal
            title="Multiple Invoice Printing"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
          >
            <Input
              style={{ width: 300 }}
              placeholder="Custom Date (Optional)"
              onChange={(val) => setCustomDate(val.target.value)}
            />
            <br />
            <br />
            <Checkbox
              onChange={(v) => {
                if (v.target.checked) {
                  setModalCheckboxOptionsSelected(modalCheckboxOptions)
                  const itemsToPrint = clients
                    .slice(0, clients.length)
                    .map((item) => item._id)
                  localStorage.setItem('itemsToPrint', itemsToPrint) // Important
                } else {
                  setModalCheckboxOptionsSelected([])
                  localStorage.removeItem('itemsToPrint')
                }
              }}
            >
              Check all
            </Checkbox>

            <Button
              type="primary"
              danger
              onClick={() => {
                onPrintAllChange()
              }}
            >
              Print now
            </Button>

            <CheckboxGroup
              options={modalCheckboxOptions}
              value={modalCheckboxOptionsSelected.map((el) => el.value)}
              onChange={onPrintAllChange}
            />
          </Modal>
        )}

        <h1 style={styles.heading}>Welcome to billing</h1>
        {/* <div style={{ display: 'flex' }}>
          <div style={{ width: '50vw' }}>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              Check all
            </Checkbox>

            <CheckboxGroup
              options={plainOptions}
              value={checkedList}
              onChange={onChange}
            />
          </div>
        </div> */}

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type={selectedType == 'hC' ? 'primary' : 'ghost'}
            onClick={() => {
              setSelectedType('hC')
              navigate(`/billing?category=hC`)
              window.location.reload()
            }}
          >
            Hospital & Clinic
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            type={selectedType == 'dL' ? 'primary' : 'ghost'}
            onClick={() => {
              setSelectedType('dL')
              navigate(`/billing?category=dL`)
              window.location.reload()
            }}
          >
            Diagnostic & Lab
          </Button>
        </div>

        <Divider />
        {/* Input company Name */}
        <Input.Search
          style={{ width: '40vw' }}
          placeholder="Company Name (Search by Name or ID)"
          enterButton="Search"
          allowClear
          onChange={(evt) => handleSearch(evt.target.value)}
          onSearch={handleSearch}
        />
        <Tooltip title="Adjust print settings to avoid unexpected overflow or underflow of content.">
          <Button
            type="primary"
            ghost
            style={{ marginLeft: 10 }}
            onClick={() => showModal()}
          >
            Print All (Current Month)
          </Button>
        </Tooltip>
        <List
          dataSource={clients}
          renderItem={(item) => (
            <List.Item
              onClick={() => {
                setSelectedClient(item)
                setBillingModalVisible(true)
                // console.log(item)
              }}
            >
              <Typography.Text style={styles.companyListItem}>
                {item.fileNumber} - {item.clientType} - {item.companyName}
              </Typography.Text>
            </List.Item>
          )}
        />
      </div>
    </AuthorizationLayout>
  )
}

const styles = {
  container: {
    padding: 10,
    margin: 10,
    fontFamily: 'Times New Roman',
  },
  heading: {
    fontSize: 30,
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
  },
  companyListItem: {
    color: '#2a5c59',
    cursor: 'pointer',
  },
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(Billings)

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

function getCurrentMonthString() {
  const month = new Date().getMonth()
  const monthString = months[month]
  return monthString
}

function getCurrentYearString() {
  const year = new Date().getFullYear()
  return year
}
