import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Space, Table, Button, Input, Tag, Progress, message } from 'antd'
import {
  SearchOutlined,
  FilterOutlined,
  EditOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import axios from 'axios'
import ChangePaidStatus from '../Modal/ChangePaidStatus'
import BillingModalOfACompany from '../Modal/BillingModalOfACompany'

var monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
var currentMonth = new Date().getMonth()

function ViewBillsTable(props) {
  const { user } = props
  const location = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)
  const categoryParamValue = searchParams.get('companies')

  const [selectedClient, setSelectedClient] = useState(null)
  const [billingModalVisible, setBillingModalVisible] = useState(false)
  const [selectedType, setSelectedType] = React.useState(categoryParamValue)
  const [getClientsLoading, setGetClientsLoading] = React.useState(false)
  const [calculatingDueLoading, setCalculatingDueLoading] = useState(false)
  const [clientsDataInitialPoint, setClientsDataInitialPoint] = useState([]) // Data without any manual filter (when directly fetching)
  const [clientsData, setClientsData] = useState([])
  const [totalDueFilter, setTotalDueFilter] = useState(`all`)

  const [totalBillPerMonth, setTotalBillPerMonth] = React.useState(0)
  const [totalCollected, setTotalCollected] = React.useState(0)
  const [totalDueLeft, setTotalDueLeft] = React.useState(0)

  const [paidStatusModalVisible, setPaidStatusModalVisible] = useState(false)
  const [paidStatusData, setPaidStatusData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const componentRef = useRef()
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              console.log(clearFilters())
              handleSearch(selectedKeys, confirm, dataIndex)
              clearFilters && handleReset(clearFilters)
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    // render: (text) => (searchedColumn === dataIndex ? text : text),
    // render: (text) => ,
  })

  const renderColumn = [
    {
      title: 'Customer ID',
      dataIndex: 'fileNumber',
      ...getColumnSearchProps('customerId'),
      key: 'customerId',
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      ...getColumnSearchProps('companyName'),
      key: 'companyName',
    },
    {
      title: 'Bill per Month',
      dataIndex: 'billPerMonth',
      key: 'billPerMonth',
    },
    {
      title: () => {
        return (
          <div style={{ display: 'flex' }}>
            <p style={{ marginRight: 5 }}>Total due</p>
            <Button
              size="small"
              onClick={() => {
                if (totalDueFilter === 'all') {
                  // its a state
                  onPaidUnpaidFilter(`due`) // its a func.
                }
                if (totalDueFilter === 'due') {
                  onPaidUnpaidFilter(`paid`)
                }
                if (totalDueFilter === 'paid') {
                  onPaidUnpaidFilter(`all`)
                }
              }}
            >
              <FilterOutlined />
            </Button>
          </div>
        )
      },
      dataIndex: 'prevousDue',
      key: 'prevousDue',
      render: (data, record) => {
        return (
          <span>
            {/* <p>{JSON.stringify(data)}</p> */}
            <Space>
              {data > 0 && (
                <p>
                  <Tag color="magenta">
                    {/* <p style={{ color: data > 0 ? 'red' : 'black' }}> */}
                    {data}
                    {/* </p> */}
                  </Tag>
                  Taka
                </p>
              )}
              {data == 0 && (
                <p>
                  {' '}
                  <Tag color="green">Paid</Tag>
                </p>
              )}
            </Space>
          </span>
        )
      },
    },
    {
      title: 'Contact',
      dataIndex: 'contactNumber',
      key: 'contactNumber',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          {/* <p>{JSON.stringify(text)}</p> */}
          <Space>
            <Button
              type="primary"
              ghost
              // icon={<PrinterOutlined />}
              onClick={() => {
                setPaidStatusModalVisible(true)
                setPaidStatusData(record)
              }}
            >
              Update Bill
            </Button>
            <Button
              type="primary"
              ghost
              icon={<PrinterOutlined />}
              onClick={() => {
                console.log(record)
                setSelectedClient(record)
                setBillingModalVisible(true)
              }}
            >
              Print Bill
            </Button>
          </Space>
        </span>
      ),
    },
  ]

  const getTotalCollectedOfAMonth = async () => {
    let lastMonthBeforeCurrentMonth = currentMonth - 1

    if (lastMonthBeforeCurrentMonth == -1) lastMonthBeforeCurrentMonth = 11

    try {
      const totalCollectedData = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/user/totalCollectedOfAMonth?month=${monthNames[lastMonthBeforeCurrentMonth]}`,
        {
          headers: {
            Authorization: `Bearer ${user.user.token}`,
          },
        }
      )
      setTotalBillPerMonth(totalCollectedData.data.billPerMonthOfAllClients)
      setTotalCollected(totalCollectedData.data.totalCollected)
      setTotalDueLeft(
        totalCollectedData.data.billPerMonthOfAllClients -
          totalCollectedData.data.totalCollected
      )
    } catch (err) {
      console.log(err)
    }
  }

  const getClientData = async (calculateDue) => {
    var config = {
      method: 'GET',
      url: `${
        process.env.REACT_APP_SERVER
      }/api/user/getAllClients?calculateDue=${calculateDue}   ${
        selectedType !== null ? `&selectedType=${selectedType}` : ''
      } `,
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      },
    }

    setGetClientsLoading(true)

    axios(config)
      .then(function (response) {
        if (response.data?.clients?.length > 0) {
          setClientsData(
            response.data.clients.sort((a, b) => {
              return a.customerId - b.customerId
            })
          )
          setClientsDataInitialPoint(
            response.data.clients.sort((a, b) => {
              return a.customerId - b.customerId
            })
          )

          if (calculateDue === `false`) {
            getClientData(`true`)
            setCalculatingDueLoading(true) // Main table loader
          } else {
            // // map over clients array and parseFloat billPerMonth and add it to a variable
            // response.data.clients.map((client) => {
            //   setTotalBillPerMonth(
            //     (prev) => prev + parseFloat(client.billPerMonth)
            //   )
            // })
            // // map over clients array
            // response.data.clients.map((client) => {
            //   setTotalDueLeft((prev) => prev + client.prevousDue)
            // })
            setCalculatingDueLoading(false) // to hide the Please wait message
          }
        }

        setGetClientsLoading(false)
      })
      .catch(function (error) {
        console.log(error)
        setGetClientsLoading(false)
        message.destroy()
        message.error('Please reload after few minutes')
        // getClientData(`true`) // trying to get data again // ! Dont do this here it is causing server stress for multiple requests
      })
  }

  // To filter data based on: all, paid, unpaid values.
  const onPaidUnpaidFilter = (type) => {
    setTotalDueFilter(type)
    console.log(type)
    if (type === 'due') {
      setClientsData(
        clientsDataInitialPoint.filter((el, idx) => {
          // console.log(el.prevousDue)
          return el.prevousDue > 0
        })
      )
    }
    if (type === 'paid') {
      setClientsData(
        clientsDataInitialPoint.filter((el, idx) => {
          // console.log(el.prevousDue)
          return el.prevousDue == 0
        })
      )
    }
    if (type === 'all') setClientsData(clientsDataInitialPoint)
  }

  useEffect(() => {
    getClientData(`false`)
    getTotalCollectedOfAMonth()
  }, [])

  return (
    <div style={{ marginTop: 20 }}>
      {paidStatusModalVisible && (
        <ChangePaidStatus
          visible={paidStatusModalVisible}
          setVisible={setPaidStatusModalVisible}
          paidStatusData={paidStatusData}
          getClientData={getClientData}
        />
      )}

      <BillingModalOfACompany
        visible={billingModalVisible}
        setVisible={setBillingModalVisible}
        selectedClient={selectedClient}
      />

      {calculatingDueLoading && (
        <p style={{ margin: 5, color: 'orangered' }}>
          We are calculating due for all clients. Please wait few more
          seconds...
        </p>
      )}

      <p style={{ margin: 10 }}>{monthNames[currentMonth]} Overview</p>
      <p style={{ margin: 10 }}>
        Collectable: <b>{capitalize(inWords(totalBillPerMonth))}</b> Bangladeshi
        Taka ({totalBillPerMonth})
      </p>

      {totalCollected >= 0 && (
        <p style={{ margin: 10 }}>
          Collected: <b>{capitalize(inWords(totalCollected))}</b> Bangladeshi
          Taka ({totalCollected}) -{' '}
          {/* in this <span>, value 11 means for December */}
          <span style={{ fontStyle: 'italic', fontSize: `0.5rem` }}>
            These are collected for month of{' '}
            {monthNames[currentMonth != 0 ? currentMonth - 1 : 11]}
          </span>
        </p>
      )}

      <p style={{ margin: 10 }}>
        Due: <b>{capitalize(inWords(totalDueLeft))}</b> Bangladeshi Taka (
        {totalDueLeft})
      </p>

      <span style={{ margin: 10 }}>Total collected: </span>
      <Progress
        style={{ width: 300 }}
        strokeColor={{
          '0%': '#C5E8B7',
          '100%': '#2EB62C',
        }}
        percent={
          totalDueLeft > 0
            ? (
                ((totalBillPerMonth - totalDueLeft) * 100) /
                totalBillPerMonth
              ).toFixed(2)
            : 0
        }
        // percent={99}
        // percent={Math.round((1000000 * 100) / totalBillPerMonth)}
      />
      {/* <p>{Math.round((1000000 * 100) / totalBillPerMonth)}</p> */}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <Button
          type={selectedType == 'hC' ? 'primary' : 'ghost'}
          onClick={() => {
            setSelectedType('hC')
            navigate(`/dashboard?category=bills&companies=hC`)
            window.location.reload()
          }}
        >
          Hospital & Clinic
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          type={selectedType == 'dL' ? 'primary' : 'ghost'}
          onClick={() => {
            setSelectedType('dL')
            navigate(`/dashboard?category=bills&companies=dL`)
            window.location.reload()
          }}
        >
          Diagnostic & Lab
        </Button>
      </div>
      <Table
        loading={getClientsLoading}
        dataSource={clientsData}
        columns={renderColumn}
        // rowKey="_id"
        scroll={{ x: true }}
        title={() => `Total clients: ${clientsData.length}`}
      />
    </div>
  )
}

const styles = {
  container: {
    padding: 10,
    margin: 10,
    fontFamily: 'Times New Roman',
  },
}
const generateBill = async (cId, token) => {
  var currentYear = new Date().getFullYear()
  // console.log(currentYear)

  // console.log(typeof monthNames[currentMonth])

  const data = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_SERVER}/api/user/generateBill`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      clientId: cId,
      billGenerationMonth: monthNames[currentMonth],
      billGenerationYear: `${currentYear}`,
    },
  })
  // console.log(data.data)
  return data.data
}

var a = [
  '',
  'one ',
  'two ',
  'three ',
  'four ',
  'five ',
  'six ',
  'seven ',
  'eight ',
  'nine ',
  'ten ',
  'eleven ',
  'twelve ',
  'thirteen ',
  'fourteen ',
  'fifteen ',
  'sixteen ',
  'seventeen ',
  'eighteen ',
  'nineteen ',
]
var b = [
  '',
  '',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
]

function inWords(num) {
  var n
  if ((num = num.toString()).length > 9) return 'overflow'
  n = ('000000000' + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/)
  if (!n) return
  var str = ''
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore '
      : ''
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh '
      : ''
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand '
      : ''
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred '
      : ''
  str +=
    n[5] != 0
      ? (str != '' ? 'and ' : '') +
        (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) +
        'only '
      : ''
  return str
}
const capitalize = (str) => {
  if (typeof str === 'string') return str.charAt(0).toUpperCase() + str.slice(1)
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(ViewBillsTable)
