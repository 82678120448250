import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { SearchOutlined } from '@ant-design/icons'
import ReactToPrint from 'react-to-print'
import { Space, Table, Button, Input, Form, DatePicker, message } from 'antd'

function ViewStatements(props) {
  const { user } = props

  const [allCosts, setAllCosts] = useState([])
  const [totalEarnings, setTotalEarnings] = useState(0)
  const [totalSpending, setTotalSpending] = useState(0)
  const [renderStatments, setRenderStatement] = useState([])
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const componentRef = useRef()
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              console.log(clearFilters())
              handleSearch(selectedKeys, confirm, dataIndex)
              clearFilters && handleReset(clearFilters)
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    // render: (text) => (searchedColumn === dataIndex ? text : text),
    // render: (text) => ,
  })

  const sharedOnCell = (_, index) => {
    if (index == renderStatments.length - 1) {
      return {
        colSpan: 0,
      }
    }
    return {}
  }

  const renderColumn = [
    {
      title: 'No.',
      // dataIndex: 'costId',
      key: 'costId',
      render: (data, record, index) => (
        <span>
          <Space>
            <p>{record.costPurpose != 'Total spending' && `${index + 1}`}</p>
          </Space>
        </span>
      ),
      onCell: sharedOnCell,
    },
    {
      title: 'Details',
      // dataIndex: 'costPurpose',
      key: 'costPurpose',
      ...getColumnSearchProps('costPurpose'),
      render: (data, record) => (
        <span>
          {/* <p>{JSON.stringify(data)}</p> */}
          <Space>
            <p>{data.costPurpose}</p>
          </Space>
        </span>
      ),
      // Todo: if costType: employees then show employee name, if costType: cars then show car number, if office then just show "Office" string.
      onCell: (_, index) => {
        // console.log(`index: ${index}`)
        // console.log(index != renderStatments.length - 1)
        return {
          colSpan: index != renderStatments.length - 1 ? 1 : 3,
        }
      },
    },
    {
      title: 'Earnings',
      dataIndex: 'earnings',
      key: 'earnings',
      onCell: sharedOnCell,
    },
    {
      title: 'Spendings',
      dataIndex: 'cost',
      key: 'cost',
      defaultSortOrder: '',
      sorter: (a, b) => a.cost - b.cost,
    },
    // {
    //   title: 'Date',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render: (dt) => moment(dt).format('DD-MM-YYYY'),
    //   sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    //   onFilter: (value, record) =>
    //     moment(record.createdAt).isSame(moment(value), 'day'),
    // },
  ]

  // ! keeping just for reference
  const customColumn = {
    title: 'Custom Column',
    render: (text, record, index) => {
      // console.log('Custom Column down')
      // console.log(index)
      // console.log(renderStatments.length - 2)
      // console.log('Custom Column up')
      if (index === renderStatments.length - 1) {
        return <p style={{ border: '1px solid red' }}>Test if</p>
      } else {
        return null
      }
    },
  }

  const getAllCosts = async () => {
    // console.log(`getAllCosts()`)
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}/api/user/getAllCosts`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Send the token in the Authorization header
          Authorization: `Bearer ${user.user.token}`,
        },
      }
    )
    const data = await response.json()
    // console.log(data.allCosts?.length)
    if (data.allCosts?.length === 0) {
      setAllCosts(data.allCosts)
    } else {
      // For test purpose only
      let myArray = new Array(10000).fill(data.allCosts[0])
      // setAllCosts([...data.allCosts, ...myArray])
      setAllCosts([...data.allCosts])
    }
  }

  const getEarnings = async (fromD, toD) => {
    // console.log(`getEarnings()`)
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}/api/user/getEarnings`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.token}`,
        },
        body: JSON.stringify({
          fromDate: fromD,
          toDate: toD,
        }),
      }
    )
    const data = await response.json()
    setTotalEarnings(data?.allBillsReceivedDuringRange)

    const obj = {
      costPurpose: 'Bill received during selected date range',
      earnings: data.allBillsReceivedDuringRange,
    }
    // on the renderStatement array add this object at 0 index add push rest of the objects
    setRenderStatement((prev) => [obj, ...prev])
  }

  const onFinish = async (values) => {
    setRenderStatement([])
    // console.log('Success:', values)
    const { fromDate, toDate } = values
    const isGreater = fromDate.isAfter(toDate)
    // console.log(isGreater) // true
    if (isGreater) {
      message.error('Start date must be less than or equal end date')
      setFromDate(null)
      setToDate(null)
      return
    }
    getEarnings(fromDate, toDate) // to show the first row of the table and get total earning

    setFromDate(fromDate)
    setToDate(toDate)
    // Get total paid bills during the selected date range

    let tempTotalSpendings = 0
    let costItemFoundToRender = 0 // if 0 item added after filter add the row outside the "isWithinRange" if block
    // console.log(allCosts.length)
    // console.log(allCosts)
    // Get total costing during the selected date range
    allCosts.forEach((cost, idx) => {
      // console.log(cost.createdAt)
      const costDate = moment(cost.createdAt).format('YYYY-MM-DD')
      // console.log(moment(cost.createdAt).format('YYYY-MM-DD'))
      const startDate = moment(values.fromDate).subtract(1, 'day')
      // const endDate = moment(values.toDate).add(1, 'day')
      // const startDate = moment(values.fromDate)
      const endDate = moment(values.toDate)
      const dateToCheck = moment(costDate)
      const isWithinRange = dateToCheck.isBetween(startDate, endDate)
      if (idx === 0) {
        // console.log(startDate)
        // console.log(endDate)
        // console.log(costDate, isWithinRange)
      } else {
        // console.log(costDate, isWithinRange)
      }
      if (isWithinRange) {
        tempTotalSpendings += cost.cost
        costItemFoundToRender += 1
        setTotalSpending((prev) => prev + cost.cost) // Just storing incase required in other place of page
        setRenderStatement((prev) => [...prev, cost])
        // console.log(idx)
        // console.log(allCosts.length)
        // console.log(`allCosts ${idx}: ${allCosts[idx].costPurpose}`)
        // console.log(allCosts[idx])
        // console.log(allCosts[10])
        if (idx === allCosts.length - 1) {
          console.log('Total spending: ', tempTotalSpendings)
          setRenderStatement((prev) => [
            ...prev,
            { costPurpose: 'Total spending', cost: tempTotalSpendings },
          ])
        }
      }

      if (costItemFoundToRender === 0 && idx === allCosts.length - 1) {
        setRenderStatement((prev) => [
          ...prev,
          { costPurpose: 'Total spending', cost: 0 },
        ])
      }
    })

    if (allCosts.length === 0) {
      setRenderStatement((prev) => [
        ...prev,
        { costPurpose: 'Total spending', cost: 0 },
      ])
    }
  }

  useEffect(() => {
    getAllCosts()
  }, [])

  return (
    <div style={{ marginTop: 20 }}>
      <ReactToPrint
        trigger={() => <button>Print</button>}
        content={() => componentRef.current}
      />
      {/* <p>Total data: {allCosts.length}</p> */}
      <div>
        <Form onFinish={onFinish}>
          <Form.Item
            label="From Date"
            name="fromDate"
            rules={[
              { required: true, message: 'Please input the start date!' },
            ]}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 20 }}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="To Date"
            name="toDate"
            rules={[{ required: true, message: 'Please input the end date!' }]}
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 20 }}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Table
        pagination={false}
        bordered
        ref={componentRef}
        dataSource={renderStatments}
        columns={[...renderColumn]}
        // rowKey="_id"
        scroll={{ x: true }}
        style={{ paddingLeft: 20, paddingRight: 20, marginBottom: 20 }}
        title={() => (
          <>
            <p style={{ textAlign: 'center', margin: 0 }}>
              <b>CHATTAGRAM SHEBA SHANGSTHA</b>
            </p>
            {fromDate && toDate && (
              <p style={{ textAlign: 'center', margin: 0 }}>
                Statement for
                <b>{moment(fromDate).format('DD MMMM YYYY')}</b> to{' '}
                <b>{moment(toDate).format('DD MMMM YYYY')}</b>
                {/* {` ${
                  fromDate ? moment(fromDate).format('DD MMMM YYYY') : ''
                } to ${toDate ? moment(toDate).format('DD MMMM YYYY') : ''}`} */}
              </p>
            )}
            {/* <p style={{ margin: 0 }}>Costings. Total data: {allCosts.length}</p> */}
          </>
        )}
      />
    </div>
  )
}

const styles = {
  container: {
    padding: 10,
    margin: 10,
    fontFamily: 'Times New Roman',
  },
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(ViewStatements)
